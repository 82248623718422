<template>
  <div id="sheet" class="div-flex-column-center" v-loading="loading">
    <!-- 表单列表界面 -->
    <div class="fill-in-information div-flex-column-center">
      <el-tabs v-model="activeName">
        <el-tab-pane label="自定义表单" name="first"> </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="70vh">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
        </el-table-column>
        <el-table-column label="操作" width="240">
          <template slot="header">
            <el-button
              @click="handleClick({}, 'add')"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              >添加</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-for="(item, index) in tableOper"
              :key="index"
              v-show="item.show && item.statusShow"
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        companyName: "", // 企业名称
        legalPersonName: "", // 法定代表人姓名
        legalPersonPhone: "", // 法定代表人手机号
        status: status, // 备案状态 -1 全部  1-审核中  2-审核不通过 3-已通过
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          title: "标题",
          key: "name",
          width: "",
        },
        {
          title: "状态",
          key: "status",
          width: "",
        },
        {
          title: "创建时间",
          key: "time",
          width: "",
        },
      ],
      tableOper: [
        {
          name: "编辑",
          key: "编辑",
          active: "edit",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          key: "删除",
          active: "delete",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "系统设置",
          "表单设置"
        );
        this.initData();
      });
    },
    /**
     *12.2 获取客户模板列表
     */
    initData() {
      let requestData = {
        userId: this.userData.userId,
        search: {},
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request(
        "post",
        "/clientTemplate/getClientTemplateList",
        requestData,
        (data) => {
          this.tableData = data.tableData;
          this.pageData.total = data.total;
        }
      );
    },
    /**
     * 表格中按钮点击事件
     */
    handleClick(item, active) {
      switch (active) {
        case "edit":
          // 编辑表单
          this.$router.push({
            path: "/main/setting/diy_sheet",
            query: {
              id: item.id,
            },
          });
          break;
        case "add":
          //   添加表单
          this.$router.push({
            path: "/main/setting/diy_sheet",
            query: {
              id: "",
            },
          });
          break;
        case "delete":
          //   删除表单
          this.$confirm(
            "此操作将永久删除该表单,不影响之前使用过的表单, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "表单设置",
      auth: "2",
      path: "/main/setting/sheet",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#sheet {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      box-sizing: border-box;
      border-radius: 5px;
      transition: 0.3s;
      margin-bottom: 8px;
    }
  }
}
</style>