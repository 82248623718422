<template>
  <div id="profile-data" class="div-flex-center">
    <div class="left">
      <div class="top box div-flex-column-center">
        <div class="title">
          <div class="text">数据统计（天）</div>
        </div>
        <div class="content">
          <div class="item">
            <div class="value">{{ dataStatistics.orderToday }}</div>
            <div class="span">订单量</div>
          </div>
          <div class="sp"></div>
          <div class="item">
            <div class="value">{{ dataStatistics.orderCom }}</div>
            <div class="span">订单完成量</div>
          </div>
          <div class="sp"></div>
          <div class="item">
            <div class="value">{{ dataStatistics.waitSign }}</div>
            <div class="span">待签名订单量</div>
          </div>
          <div class="item">
            <div class="value">{{ dataStatistics.orderAll }}</div>
            <div class="span">代理总订单</div>
          </div>
          <div
            class="sp"
            v-if="
              dataStatistics.areaOrderToday &&
              dataStatistics.areaOrderToday != ''
            "
          ></div>
          <div
            class="item"
            v-if="
              dataStatistics.areaOrderToday &&
              dataStatistics.areaOrderToday != ''
            "
          >
            <div class="value">{{ dataStatistics.areaOrderToday }}</div>
            <div class="span">区域总订单</div>
          </div>
        </div>
      </div>
      <div class="bottom box div-flex-column-center">
        <div class="title">
          <div class="text">访问量</div>
        </div>
        <div class="search">
          <div class="buts">
            <div
              :class="{ but: true, active: visitsNum.status == '1' }"
              @click="visitsNum.status = '1'"
            >
              今日
            </div>
            <div class="sp"></div>
            <div
              :class="{ but: true, active: visitsNum.status == '2' }"
              @click="visitsNum.status = '2'"
            >
              昨日
            </div>
            <div class="sp"></div>
            <div
              :class="{ but: true, active: visitsNum.status == '3' }"
              @click="visitsNum.status = '3'"
            >
              近7日
            </div>
            <div class="sp"></div>
            <div
              :class="{ but: true, active: visitsNum.status == '4' }"
              @click="visitsNum.status = '4'"
            >
              汇总
            </div>
          </div>
          <el-date-picker
            v-model="visitsNum.value"
            type="daterange"
            value-format="yyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeTime"
          >
          </el-date-picker>
        </div>
        <div class="content">
          <div class="item">
            <div class="span">访问数(人)</div>
            <div class="value">{{ visitsNum.users }}</div>
            <div
              class="span"
              v-if="visitsNum.status != '4' && visitsNum.status != '5'"
            >
              <span>{{
                visitsNum.status == "1"
                  ? "较昨日"
                  : visitsNum.status == "2"
                  ? "较前天"
                  : visitsNum.status == "3"
                  ? "较前7天"
                  : ""
              }}</span>
              <img
                :src="require('@/assets/img/tt.png')"
                alt=""
                v-if="visitsNum.usersCompare == '0'"
                :style="{ width: '30px', height: '30px' }"
              />
              <img
                :src="require('@/assets/img/dd.png')"
                alt=""
                v-else-if="visitsNum.usersCompare == '1'"
                :style="{ width: '30px', height: '30px' }"
              />
            </div>
          </div>
          <div class="sp"></div>
          <div class="item">
            <div class="span">访问量(次)</div>
            <div class="value">{{ visitsNum.times }}</div>
            <div
              class="span"
              v-if="visitsNum.status != '4' && visitsNum.status != '5'"
            >
              <span>{{
                visitsNum.status == "1"
                  ? "较昨日"
                  : visitsNum.status == "2"
                  ? "较前天"
                  : visitsNum.status == "3"
                  ? "较前7天"
                  : ""
              }}</span>
              <img
                :src="require('@/assets/img/tt.png')"
                alt=""
                v-if="visitsNum.timesCompare == '0'"
                :style="{ width: '30px', height: '30px' }"
              />
              <img
                :src="require('@/assets/img/dd.png')"
                alt=""
                v-else
                :style="{ width: '30px', height: '30px' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right box div-flex-column-center">
      <div class="title">
        <div class="text">排行榜(Top 20)</div>
      </div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column label="排名" width="80">
            <template slot-scope="scope">
              <div class="ic">
                <img
                  :src="require('@/assets/img/no1_1.png')"
                  alt=""
                  v-if="scope.$index == 0"
                  :style="{ width: '30px', height: '30px' }"
                />
                <img
                  :src="require('@/assets/img/no2_1.png')"
                  alt=""
                  v-else-if="scope.$index == 1"
                  :style="{ width: '30px', height: '30px' }"
                />
                <img
                  :src="require('@/assets/img/no3_1.png')"
                  alt=""
                  v-else-if="scope.$index == 2"
                  :style="{ width: '30px', height: '30px' }"
                />
                <span v-else>{{ scope.$index + 1 }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="企业名称">
          </el-table-column>
          <el-table-column prop="area" label="区域" width="80">
          </el-table-column>
          <el-table-column prop="type" label="类型" width="80">
          </el-table-column>
          <el-table-column prop="num" label="总订单数" width="80">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      tableOper: [
        {
          name: "纳税查看",
          key: "查看详情",
          active: "show",
          show: false, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],
      tableData: [],
      dataStatistics: {
        orderToday: "", // 当天(该代理)一共办理了多少订单
        areaOrderToday: "", // 当天该区域一共办理了多少订单(只有二级代理有, 其他角色没有该key)
        orderCom: "", // 当天完成了多少订单
        waitSign: "", // 待签名的总数
        orderAll: "", // 总订单
      },
      visitsNum: {
        status: "1", // 1今日 2昨日 3七日 4汇总 5自定义
        value: [],
        date1: "", // 开始日期 年月日 status=5才有值
        date2: "", // 结束日期 年月日 status=5才有值

        users: "", // 访问人数
        times: "", // 访问次数
        usersCompare: "1", // 0上升 1下降 status=123时才有 45没有此key
        timesCompare: "1", // 0上升 1下降 status=123时才有 45没有此key
      },
    };
  },
  methods: {
    /**
     * 2.3 获取当前角色权限
     */
    getUserAuthority() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.tableOper = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tableOper,
          "企业管理",
          "客户管理"
        );
        this.getVisitsNum();
      });
    },
    /**
     * 17.1 访问量统计
     * get:
     * users: "", // 访问人数
     * times: "", // 访问次数
     * usersCompare: "", // 0上升 1下降 status=123时才有 45没有此key
     * timesCompare: "", // 0上升 1下降 status=123时才有 45没有此key
     */
    getVisitsNum() {
      let requestData = {
        status: this.visitsNum.status, // 1今日 2昨日 3七日 4汇总 5自定义
        date1: this.visitsNum.value.length == 2 ? this.visitsNum.value[0] : "", // 开始日期 年月日 status=5才有值
        date2: this.visitsNum.value.length == 2 ? this.visitsNum.value[0] : "", // 结束日期 年月日 status=5才有值
      };
      this.$request("post", "/statistics/getVisitsNum", requestData, (data) => {
        this.visitsNum.users = data.users;
        this.visitsNum.times = data.times;
        this.visitsNum.usersCompare = data.usersCompare
          ? data.usersCompare
          : "";
        this.visitsNum.timesCompare = data.timesCompare
          ? data.timesCompare
          : "";
        this.getDataStatistics();
      });
    },
    /**
     *17.2 数据统计
     * get:
     * orderToday: "", // 当天(该代理)一共办理了多少订单
     * areaOrderToday: "", // 当天该区域一共办理了多少订单(只有二级代理有, 其他角色没有该key)
     * orderCom: "", // 当天完成了多少订单
     * waitSign: "", // 待签名的总数
     * orderAll: "", // 待签名的总数
     */
    getDataStatistics() {
      let requestData = {
        userId: this.userData.userId, // 用户id
      };
      this.dataStatistics.orderToday = "";
      this.dataStatistics.orderCom = "";
      this.dataStatistics.waitSign = "";
      this.dataStatistics.orderAll = "";
      this.dataStatistics.areaOrderToday = "";
      this.$request(
        "post",
        "/statistics/dataStatistics",
        requestData,
        (data) => {
          this.dataStatistics.orderToday = data.orderToday;
          this.dataStatistics.orderCom = data.orderCom;
          this.dataStatistics.waitSign = data.waitSign;
          this.dataStatistics.orderAll = data.orderAll;
          this.dataStatistics.areaOrderToday = data.areaOrderToday
            ? data.areaOrderToday
            : "";
          this.orderRank();
        }
      );
    },
    /**
     * 17.3 订单排行
     */
    orderRank() {
      let requestData = {};
      this.$request("post", "/statistics/orderRank", requestData, (data) => {
        this.tableData = [];
        data.forEach((element, index) => {
          if (index < 20) {
            this.tableData.push(element);
          }
        });
      });
    },
    /**
     * 访问量时间段选择
     */
    changeTime() {
      this.visitsNum.status = "5";
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "数据概况",
      auth: "2",
      path: "/main/profile/profile_data",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#profile-data {
  width: 100%;
  height: 100%;
  > .left {
    width: 54%;
    height: 95%;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    > .top {
      width: 100%;
      height: 48%;
      //   background-color: aqua;
      > .content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        // justify-content: space-between;
        align-content: flex-start;
        > .item {
          width: 30%;
          height: 45%;
          margin-top: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 1%;
          > .value {
            font-size: 25px;
            color: @baseColor;
            font-weight: bolder;
          }
          > .span {
            font-size: 12px;
          }
        }
        > .sp {
          width: 1px;
          background-color: rgba(180, 180, 180, 0.164);
          height: 39%;
          margin-top: 3%;
        }
      }
    }
    > .bottom {
      width: 100%;
      height: 48%;
      margin-top: 2%;
      > .search {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
        > .buts {
          width: auto;
          padding: 0 10px;
          height: 30px;
          border-radius: 8px;
          background-color: #d6a62496;
          border: 1px solid rgba(77, 77, 77, 0.192);
          box-sizing: border-box;
          font-size: 12px;
          color: #333;
          display: flex;
          margin-right: 10px;
          > .but {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              background-color: #a3831a96;
              color: #fff;
            }
          }
          > .active {
            background-color: #a3831a96;
            color: #fff;
          }
          > .sp {
            width: 1px;
            height: 100%;
            background-color: rgba(77, 77, 77, 0.192);
          }
        }
      }
      > .content {
        width: 100%;
        height: 0;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        // justify-content: space-between;
        align-content: flex-start;

        > .item {
          width: 48%;
          height: 90%;
          margin-top: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 1%;
          > .value {
            font-size: 30px;
            color: @baseColor;
            font-weight: bolder;
            height: 40%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          > .span {
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bolder;
            img {
              margin-left: 10px;
            }
          }
        }
        > .sp {
          width: 1px;
          background-color: rgba(180, 180, 180, 0.164);
          height: 90%;
          margin-top: 3%;
        }
      }
    }
  }
  > .right {
    width: 42%;
    height: 95%;
    > .table {
      width: 100%;
      height: 0;
      flex: 1;
      padding: 10px;
      box-sizing: border-box;
    }
  }
  .title {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 8px;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    align-content: flex-start;
    border-bottom: 1px solid #97979721;
    > .text {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bolder;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
  }
  .box {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .ic {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
  }
}
</style>