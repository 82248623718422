<template>
  <div id="document" class="div-flex-center" v-loading="loading">
    <!-- 文档管理 -->
    <div class="left div-flex-column-center">
      <div
        :class="{
          menu: true,
          'div-flex-center': true,
          'is-active': isActive == item.name,
        }"
        v-show="item.auth == '1'"
        v-for="(item, index) in menus"
        :key="index"
        @click="jump(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="right"><router-view /></div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  data() {
    return {
      isActive: "",
      loading: true,
      menuList: ["/main/document/document_list"],
      menus: [
        {
          id: "",
          name: "新设立",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "迁址",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "续签",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "代理管理",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "小程序",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "个人中心",
          auth: "2",
          path: "/main/document/document_list",
        },
        {
          id: "",
          name: "企业管理",
          auth: "2",
          path: "/main/document/document_list",
        },
      ],
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
    };
  },
  methods: {
    /**
     * 路由跳转
     */
    jump(data) {
      sessionStorage.setItem("childernMenu", JSON.stringify(data));
      sessionStorage.setItem("path", data.path);
      this.isActive = data.name;
      this.$router.push({
        path: data.path,
        query: {
          name: data.name,
        },
      });
      this.reload();
    },
  },
  mounted() {
    this.loading = true;
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
      this.menus = this.$baseData.judgeDirectoryPermissions(
        data,
        this.menus,
        "帮助文档"
      );
    });
    let name = "";
    let path = "";
    this.loading = false;
    this.isActive =
      sessionStorage.getItem("childernMenu") &&
      this.menuList.indexOf(
        JSON.parse(sessionStorage.getItem("childernMenu")).path
      ) >= 0
        ? JSON.parse(sessionStorage.getItem("childernMenu")).name
        : "新设立";
    path =
      sessionStorage.getItem("childernMenu") &&
      this.menuList.indexOf(
        JSON.parse(sessionStorage.getItem("childernMenu")).path
      ) >= 0
        ? JSON.parse(sessionStorage.getItem("childernMenu")).path
        : "/main/document/document_list";
    this.$router.push({
      path: path,
      query: {
        name: this.isActive,
      },
    });
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
#document {
  width: 100%;
  height: 100%;
  .left {
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    border-right: 1px solid @borderColor;
    // border-left: 1px solid @borderColor;

    .menu {
      width: 100%;
      height: 8%;
      border-bottom: 1px solid #33333300;
      border-left: 1px solid #33333300;
      box-sizing: border-box;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: rgba(201, 201, 201, 0.212);
        border-left: 5px solid @activeColor;
        color: @activeColor;
      }
    }
    .is-active {
      background-color: rgba(201, 201, 201, 0.212);
      border-left: 5px solid @activeColor;
      color: @activeColor;
    }
  }
  .right {
    width: 0;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }
}
</style>