import mobilePhoneNumberCheck from "../../utils/mobilePhoneNumberCheck.js"
export function getSheetListData() {
    return [{
        title: "客户基本信息补充",
        tip: "",
        data: [{
            title: "时间:",
            tip: true, // 是否不得为空
            type: "date_time", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "areaName",
            format: "yyyy-MM" // 时间值的格式
        }, {
            title: "表单名称:",
            tip: true, // 是否不得为空
            type: "select_obj", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入区域名称", // 提示文字
            key: "areaName",
        }, ]
    }]
}

/**
 * 获取企业工商信息
 */
export function getCompanyInfo() {
    return [{
        title: "工商信息",
        tip: "",
        data: [{
            title: "企业名称",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "统一信用代码",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientCode",
        }, {
            title: "法定代表人",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "法定代表人手机号",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "实际经营地址",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "证件类型",
            tip: true, // 是否不得为空
            type: "select", // 仅文字展示
            value: "",
            options: [{ label: "身份证", value: "身份证" }],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "证件号码",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "邮箱",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, {
            title: "备注",
            tip: false, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: "clientName",
        }, ]
    }]
}

/**
 * 格式化工商信息数据
 */
export function formatCompanyInfo(data) {
    let returnData = {
        flag: true,
        mes: ""
    }
    data.forEach(element => {
        if (element.tip && element.value == "") {
            returnData.flag = false
            returnData.mes = "请将信息填写完整!"
        }
        if (element.title.indexOf("手机号") >= 0 && !mobilePhoneNumberCheck(element.value)) {
            returnData.flag = false
            returnData.mes = "请填写正确格式的手机号!"
        }
    });

    return returnData
}