<template>
  <div id="diy-sheet" class="div-flex-column-center" v-loading="loading">
    <!-- 自定义表单设置 -->
    <div class="fill-in-information div-flex-column-center">
      <el-tabs v-model="activeName">
        <el-tab-pane label="自定义表单" name="first"> </el-tab-pane>
      </el-tabs>
    </div>
    <div class="content div-flex-column-center">
      <div class="title-line div-flex-center">
        <div class="title">
          <div class="tip">*</div>
          表单状态
        </div>
        <div class="value div-flex-center div-flex-start">
          <el-switch v-model="status"> </el-switch>
        </div>
      </div>
      <div class="title-line div-flex-center">
        <div class="title">
          <div class="tip">*</div>
          表单名称
        </div>
        <div class="value div-flex-center div-flex-start">
          <el-input v-model="title" placeholder="请输入内容"></el-input>
        </div>
      </div>

      <div class="value-data div-flex-center">
        <div class="title">表单设置</div>
        <div class="value div-flex-center div-flex-start">
          <div class="type-list">
            <div
              class="type-item div-flex-center"
              v-for="(item, index) in typeData"
              :key="index"
              @click="addItem(item)"
            >
              {{ item.typeName }}
            </div>
          </div>
          <div class="type-data" v-if="data.length > 0">
            <SheetList :sheetListData="data"></SheetList>
          </div>
        </div>
      </div>

      <div class="title-line-buts">
        <div class="but" @click="save">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as settingData from "../../assets/js/setting.js";
import SheetList from "../../components/sheet-list.vue";
export default {
  components: { SheetList },
  data() {
    return {
      activeName: "first",
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      status: true,
      title: "",
      data: [],
      typeData: settingData.getTypeList(),
      id: "",
    };
  },
  methods: {
    /**
     * 获取当前
     */
    getSheetDetials() {
      let requestData = {
        id: this.id,
      };
      this.$request(
        "post",
        "/clientTemplate/getClientTemplateById",
        requestData,
        (data) => {
          this.data = data.data;
          this.title = data.title;
          this.status = data.status == "0" ? true : false;
        }
      );
    },
    /**
     * 添加表单
     */
    addItem(item) {
      let dd = JSON.parse(JSON.stringify(item));
      this.data.push(dd);
    },
    /**
     * 保存表单
     */
    save() {
      console.log("当前表单数据:", this.data);
      let requestData = {
        title: this.title,
        status: this.status ? "0" : "1",
        data: this.data,
        id: this.id,
      };

      requestData.data.forEach((element) => {
        if (element.type == "4" || element.type == "5") {
          // 单选  或 多选选项值处理  label和value相同
          element.options.forEach((opt) => {
            opt.value = opt.label;
          });
        }
      });
      let url =
        this.id == ""
          ? "/clientTemplate/creatClientTemplate"
          : "/clientTemplate/updateClientTemplate";
      this.$request("post", url, requestData, (data) => {
        this.$message({
          message: "表单创建成功！",
          type: "warning",
        });
        this.$router.push("/main/setting/sheet");
      });
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "表单设置",
      auth: "2",
      path: "/main/setting/diy_sheet",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.id = this.$route.query.id;
    if (this.id != "") {
      this.getSheetDetials();
    }
    // this.getUserAuthority();
  },
};
</script>

<style lang="less" scoped>
#diy-sheet {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    overflow-y: auto;
    .title-line,
    .value-data {
      width: 100%;
      .title {
        width: 8%;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        font-size: 14px;
        margin-right: 10px;
        .tip {
          width: auto;
          height: 100%;
          display: flex;
          align-items: flex-start;
          margin-top: 5px;
          margin-right: 8px;
          justify-content: center;
          color: red;
        }
      }
      .value {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: flex-start;
        .type-list,
        .type-data {
          width: 20%;
          height: auto;
          padding: 10px 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-color: rgba(195, 195, 195, 0.335);
          .type-item {
            width: 100%;
            height: 35px;
            background-color: #ffffff;
            margin: 5px 0;
            font-size: 14px;
            transition: 0.3s;
            &:hover {
              cursor: pointer;
              color: @activeColor;
              background-color: #f3f3f3c7;
            }
          }
        }
        .type-data {
          width: 0;
          flex: 1;
          margin-left: 30px;
        }
      }
    }
    .title-line {
      height: 50px;
      min-height: 50px;
    }
    .value-data {
      height: auto;
    }
    .title-line-buts {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      .but {
        width: auto;
        height: auto;
        padding: 10px 30px;
        border-radius: 5px;
        background-color: @baseColor;
        color: #ffffff;
        font-size: 14px;
        transition: 0.3s;
        margin-left: 8%;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }
  }
}
</style>