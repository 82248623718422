<template>
  <div id="information-text" class="div-flex-column-center">
    <!-- 信息填写组件 -->
    <div
      class="model div-flex-column-center"
      v-for="(item, index) in informationTextData"
      :key="index"
    >
      <div class="title">
        <div class="line"></div>
        <div class="text">
          {{ item.title }}<span>{{ item.tip }}</span>
        </div>
      </div>
      <div class="content">
        <div
          :class="{ line: true, 'line-ver': it.landscape }"
          v-for="(it, ii) in item.data"
          :key="ii"
          v-show="
            it.title != '验证码:' && !(it.type == 'input_show' && !it.show)
          "
        >
          <div class="line-title" v-if="it.type != 'text-tip'">
            <span v-if="it.tip">*</span>{{ it.title }}
          </div>
          <div class="line-value" v-if="it.type != 'file_reuse'">
            <span :style="{ color: it.type == 'tip' ? '#f00' : '#333' }">{{
              it.value
            }}</span>
            <span v-if="it.type == 'pedometer'">万元</span>
          </div>
          <div class="line-value file_text" v-else @click="showFile(it)">
            {{ it.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["informationTextData"],
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
    };
  },
  methods: {
    showFile(item) {
      // console.log(item);
      window.open(item.value, "_blank");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#information-text {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .model {
    width: 100%;
    height: auto;
    .title {
      width: 100%;
      height: 35px;
      position: relative;
      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(160, 160, 160, 0.288);
        position: absolute;
        left: 0;
        top: 10px;
      }
      .text {
        width: auto;
        background-color: #ffffff;
        height: 100%;
        position: absolute;
        left: 30px;
        padding: 0 8px;
        font-size: 14px;
        span {
          color: red;
          margin-left: 8px;
        }
      }
    }
    .content {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .line {
        width: 49%;
        height: auto;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 8px 0;
        .line-title {
          width: 200px;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          span {
            color: red;
            margin-top: 3px;
            margin-right: 3px;
            font-size: 18px;
          }
        }
        .line-value {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-start;
          margin-left: 10px;
          .div1 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .textarea-but {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .file_text {
          color: @baseColor;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            color: @activeColor;
          }
        }

        .line-but {
          width: auto;
          min-width: 65px;
          height: 30px;
          padding: 0 15px;
          margin: 0 0 0 15px;
          background-color: @baseColor;
          color: #fff;
          font-size: 13px;
          border-radius: 8px;
          transition: 0.3s;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
        }
        .line-but-disable {
          background-color: @but-disableColor;
          &:hover {
            background-color: @but-disableColor;
            cursor: not-allowed;
          }
        }
      }
      .line-ver {
        // 横屏展示
        width: 98%;
      }
    }
  }

  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}
</style>