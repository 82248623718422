<template>
  <div id="main" class="div-flex-column-center div-flex-column-center-center">
    <div class="top-menu div-flex-center">
      <div class="top-logo div-flex-center">
        <!-- <img :src="logo" alt="" style="height: 30px" /> -->
        安徽广电企服平台
      </div>
      <div class="perch-active div-flex-center"></div>
      <div class="menus div-flex-center">
        <div
          :class="{
            menu: true,
            'div-flex-center': true,
            'is-active': isActive == item.name,
          }"
          v-show="item.auth == '1'"
          v-for="(item, index) in menus"
          :key="index"
        >
          <span @click="jump(item)">{{ item.name }}</span>
        </div>
      </div>
      <div class="users div-flex-center">
        <div class="user-name div-flex-center div-flex-end">
          {{ userData.userName }}
        </div>
        <div class="user-exit div-flex-center div-flex-end" @click="gotoLogin">
          退出
        </div>
        <div class="user-icon div-flex-center div-flex-end">
          <img
            style="width: 50px; height: 50px; borderradius: 20px"
            :src="userData.icon"
            object-fit="contain"
          />
        </div>
      </div>
    </div>
    <div class="content" v-if="isActive != '首页'">
      <router-view v-if="isRouterAlive" />
    </div>
    <div class="content index" v-else>
      <div class="top">
        <div>安徽广电企服平台</div>
        <div>管理服务系统</div>
      </div>
      <div class="below">
        <div class="but" @click="stopBusiness">企业集群地址申请</div>
        <div class="tip">
          该系统申请下来的地址仅用于企业向安徽省各级市场监督管理部门及其他部门办理登记注册时使用，<span
            >不用作企业的实际经营场所、办公场所或其他场所用途。</span
          >
        </div>
      </div>
    </div>
    <div class="bottom-tip div-flex-center" style="color: #000">
      {{ this.$copyright }}
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      isActive: "首页",
      logo: require("../assets/img/logo.png"),
      menus: [
        {
          id: "",
          name: "首页",
          auth: "1", // 1 显示 2 不显示
          path: "/main/index",
        },
        {
          id: "",
          name: "业务办理",
          auth: "1", // 1 显示 2 不显示
          path: "/main/order",
        },
        {
          id: "",
          name: "企业管理",
          auth: "1", // 1 显示 2 不显示
          path: "/main/company",
        },
        {
          id: "",
          name: "个人中心",
          auth: "1", // 1 显示 2 不显示
          path: "/main/personal_center",
        },
        {
          id: "",
          name: "代理管理",
          auth: "1", // 1 显示 2 不显示
          path: "/main/agent",
        },
        {
          id: "",
          name: "帮助文档",
          auth: "1", // 1 显示 2 不显示
          path: "/main/document",
        },
        {
          id: "",
          name: "集群资讯",
          auth: "1", // 1 显示 2 不显示
          path: "/main/news",
        },
        {
          id: "",
          name: "数据中心",
          auth: "1", // 1 显示 2 不显示
          path: "/usens",
        },
        {
          id: "",
          name: "数据概况",
          auth: "1", // 1 显示 2 不显示
          path: "/main/profile",
        },
        {
          id: "",
          name: "系统设置",
          auth: "1", // 1 显示 2 不显示
          path: "/main/setting",
        },
      ],
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
    };
  },
  methods: {
    /**
     * 回到登录页
     */
    gotoLogin() {
      sessionStorage.removeItem("topMenu");
      sessionStorage.removeItem("childernMenu");
      this.$router.push("/");
    },

    /**
     * 路由跳转
     */
    jump(data) {
      if (data.name == "数据中心") {
        sessionStorage.removeItem("topMenu");
        sessionStorage.removeItem("childernMenu");
        this.$router.push(data.path);
      } else {
        sessionStorage.setItem("topMenu", JSON.stringify(data));
        this.isActive = data.name;
        this.$router.push(data.path);
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    /**
     * 首页点击企业集群地址申请
     */
    stopBusiness() {
      let dd = [
        {
          id: "",
          name: "新设立申请",
          auth: "2",
          path: "/main/order/instructions",
        },
        {
          id: "",
          name: "订单管理",
          auth: "2",
          path: "/main/order/order_management",
        },
      ];
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        dd = this.$baseData.judgeDirectoryPermissions(data, dd, "业务办理");
        if (dd[0].auth == "1") {
          sessionStorage.setItem("childernMenu", JSON.stringify(dd[0]));
        } else if (dd[1].auth == "1") {
          sessionStorage.setItem("childernMenu", JSON.stringify(dd[1]));
        } else {
          this.$message({
            type: "error",
            message: "您没有权限进行业务办理!",
          });
          return;
        }
        this.jump({
          id: "",
          name: "业务办理",
          auth: "1", // 1 显示 2 不显示
          path: "/main/order",
        });
      });
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.isActive = sessionStorage.getItem("topMenu")
      ? JSON.parse(sessionStorage.getItem("topMenu")).name
      : "首页";
    let path = sessionStorage.getItem("topMenu")
      ? JSON.parse(sessionStorage.getItem("topMenu")).path
      : "/main/index";
    this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
      // 系统设置只对1级总公司开放
      console.log("总权限", data);
      this.menus = this.$baseData.determineCurrentMenu(
        data[0].model,
        this.menus
      );
      if (data[0].roleId == "1") {
        this.menus[8].auth = "1";
      }
      if (data[0].roleId == "3") {
        // 代理管理不对3级管理开放
        this.menus[4].auth = "2";
      }
      // if (data[0].roleId == "1") {
      //   // 代理管理不对3级管理开放
      //   this.menus[7].auth = "1";
      // } else {
      //   this.menus[7].auth = "2";
      // }
    });
    this.$router.push(path);
  },
};
</script>

<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  background-color: rgba(187, 187, 187, 0.13);
  .top-menu {
    width: 100%;
    height: 10%;
    background-color: rgb(85, 85, 85);
    .top-logo {
      width: auto;
      padding: 0 3%;
      height: 100%;
      letter-spacing: 6px;
      font-weight: bolder;
      font-size: 2vw;
      color: #fff;
      img {
        margin-right: 10px;
        margin-top: 5px;
      }
    }

    .menus {
      width: auto;
      height: 100%;
      padding: 0 3%;
      .menu {
        width: auto;
        padding: 0 1vw;
        height: 90%;
        margin-top: 7%;
        font-size: 0.8vw;
        color: #fff;
        span {
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            color: @activeColor;
          }
        }
      }
      .is-active {
        color: @activeColor;
      }
    }

    .users {
      width: auto;
      height: 100%;
      padding: 0 1% 0 2%;
      font-size: 0.8vw;
      .user-name,
      .user-exit {
        width: auto;
        height: 100%;
        padding: 0 10px;
        margin-bottom: 18%;
        color: #fff;
        transition: 0.3s;
      }
      .user-exit:hover {
        cursor: pointer;
        color: @activeColor;
      }
      .user-icon {
        width: auto;
        padding: 0 20px;
        height: 100%;
        margin-bottom: 10%;
      }
    }
  }
  .content {
    width: 75%;
    height: 86%;
    background-color: #fff;
    margin-top: 10px;
    border-right: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    overflow: hidden;
  }
  .index {
    width: 100%;
    display: flex;
    flex-direction: column;
    .top {
      width: 100%;
      height: 80%;
      background-image: url("../assets/img/login_bg.png");
      background-size: 100% auto;
      background-position: top;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // color: @activeColor;
      color: #fff;
      font-weight: 800;
      font-size: 2.5vw;
      letter-spacing: 10px;
      div {
        width: 100%;
        padding-left: 16%;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        &:nth-last-child(1) {
          padding-left: 19%;
          margin-top: 1.5%;
        }
      }
      // font-family: yrdzst;
    }
    .below {
      width: 100%;
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .but {
        width: auto;
        height: auto;
        padding: 15px 30px;
        background-color: @baseColor;
        border-radius: 10px;
        color: #fff;
        font-size: 0.8vw;
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
      .tip {
        margin-top: 20px;
        width: 30%;
        font-size: 0.7vw;
        span {
          font-weight: bolder;
        }
      }
    }
  }
  .bottom-tip {
    width: 100%;
    height: 4%;
    font-size: @font-size-tip-copyright;
    // background-color: #fff;
  }
}
</style>