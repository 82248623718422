
<template>
  <div id="EchartsMapLine">
    <div id="mainLine" style="width: 100%; height: 100%"></div>
  </div>
</template>
 
<script>
export default {
  props: {
    xData: {
      type: Array,
      default: function () {
        return [
          "庐阳区",
          "蜀山区",
          "包河区",
          "瑶海区",
          "肥东县",
          "肥西县",
          "高新区",
        ];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [820, 932, 901, 934, 1290, 1330, 1320];
      },
    },
  },
  name: "EchartsMapLine",
  data() {
    return {};
  },
  methods: {
    myEcharts() {
      let buildingsGeoJSON = require("../assets/json/合肥市.json");
      //   var regions = buildingsGeoJSON.features.map(function (feature) {
      //   return {
      //     name: feature.properties.name,
      //     value: Math.random(),
      //     height: feature.properties.height / 10
      //   };
      // });
      let myChart = this.$echarts.init(document.getElementById("mainLine"));
      this.$echarts.registerMap("buildings", buildingsGeoJSON);
      //配置图表
      let option = {
        //悬浮提示框
        grid: {
          left: "1%",
          right: "4%",
          bottom: "-1%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        dataZoom: {
          start: 0,
          end: 40,
          type: "inside",
          disabled: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData,
          axisLine: {
            lineStyle: {
              color: "#00e8fe",
              width: 1,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: true,
          type: "value",
          name: "数据指数",
          nameLocation: "end",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00e8fe",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(0, 232, 254,0.15)",
            },
          },
        },
        series: [
          {
            data: this.data,
            type: "line",
            smooth: true,
            symbolSize: 6,
            symbol: "circle",
            itemStyle: {
              color: "#ffa200",
            },
            // areaStyle: {
            //   color: "rgba(15, 26, 99, 0.9)",
            // },
            areaStyle: {
              color: "rgba(49, 54, 150, 0.69)",
            },
            lineStyle: {
              color: "#00e8fe",
              width: 1,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    setTimeout(() => {
      this.myEcharts();
    }, 500);
  },
};
</script>
 
<style lang="less">
#EchartsMapLine {
  width: 100%;
  height: 100%;
}
</style>