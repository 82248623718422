<template>
  <div id="science-technology-are-digital">
    <div class="title">{{ title }}</div>
    <div class="data">{{ data }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function () {
        return "今日新增企业数";
      },
    },
    data: {
      type: String,
      default: function () {
        return "1,256";
      },
    },
  },
};
</script>

<style lang="less" scoped>
#science-technology-are-digital {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    width: 100%;
    height: auto;
    padding: 10px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #01c0f4;
    font-weight: bolder;
    letter-spacing: 2px;
  }
  .data {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: digital;
    color: #64f8ff;
    font-size: 75px;
    font-weight: bolder;
    letter-spacing: 8px;
  }
}
</style>