import Vue from 'vue'
/**
 * 获取权限模拟数据
 */
export function getAllAuthorityTree() {
    return [{
        id: true, // 模块ID
        name: "业务模块",
        children: [{
            id: "1-1", // 子模块ID
            name: "订单管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "1-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-2", // 功能ID
                name: "修改", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-3", // 功能ID
                name: "删除", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-4", // 功能ID
                name: "审核", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-5", // 功能ID
                name: "分配", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-6", // 功能ID
                name: "导出", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-7", // 功能ID
                name: "签名", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-8", // 功能ID
                name: "下载", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-1-9", // 功能ID
                name: "续签", // 功能名称
            }, ],
        }, {
            id: "1-2", // 子模块ID
            name: "业务办理",
            children: [{
                auth: "2", // 具体功能ID
                id: "1-2-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-2-2", // 功能ID
                name: "修改", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-2-3", // 功能ID
                name: "新建", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-2-4", // 功能ID
                name: "迁入", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "1-2-5", // 功能ID
                name: "续签", // 功能名称
            }],
        }, ],
    }, {
        id: "2", // 模块ID
        name: "企业管理",
        children: [{
            id: "2-1", // 子模块ID
            name: "客户管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "2-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "2-1-2", // 功能ID
                name: "导出", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "2-1-3", // 功能ID
                name: "信息录入", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "2-1-4", // 功能ID
                name: "打卡提醒", // 功能名称
            }, ],
        }],
    }, {
        id: "3", // 模块ID
        name: "个人中心",
        children: [{
            id: "3-1", // 子模块ID
            name: "个人信息",
            children: [{
                auth: "2", // 具体功能ID
                id: "3-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "3-1-2", // 功能ID
                name: "编辑", // 功能名称
            }, ],
        }],
    }, {
        id: "4", // 模块ID
        name: "代理管理",
        children: [{
            id: "4-1", // 子模块ID
            name: "备案管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "4-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-1-2", // 功能ID
                name: "备案申请", // 功能名称
            }, ],
        }, {
            id: "4-2", // 子模块ID
            name: "代理凭证",
            children: [{
                auth: "2", // 具体功能ID
                id: "4-2-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-2-2", // 功能ID
                name: "代理额度申请", // 功能名称
            }, ],
        }, {
            id: "4-3", // 子模块ID
            name: "代理管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "4-3-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-3-2", // 功能ID
                name: "审核", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-3-3", // 功能ID
                name: "凭证下发", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-3-4", // 功能ID
                name: "额度分配", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-3-5", // 功能ID
                name: "新增/修改区域代理员工", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "4-3-6", // 功能ID
                name: "删除代理", // 功能名称
            }, ],
        }],
    }, {
        id: "5", // 模块ID
        name: "帮助文档",
        children: [{
            id: "5-1", // 子模块ID
            name: "文档管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "5-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "5-1-2", // 功能ID
                name: "上传", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "5-1-3", // 功能ID
                name: "删除", // 功能名称
            }, ],
        }, ],
    }, {
        id: "6", // 模块ID
        name: "集群资讯",
        children: [{
            id: "6-1", // 子模块ID
            name: "资讯管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "6-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "6-1-2", // 功能ID
                name: "上传", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "6-1-3", // 功能ID
                name: "删除", // 功能名称
            }, ],
        }, ],
    }, {
        id: "7", // 模块ID
        name: "系统设置",
        children: [{
            id: "7-1", // 子模块ID
            name: "权限管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "7-1-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "7-1-2", // 功能ID
                name: "编辑", // 功能名称
            }, ],
        }, {
            id: "7-2", // 子模块ID
            name: "区域管理",
            children: [{
                auth: "2", // 具体功能ID
                id: "7-2-1", // 功能ID
                name: "查看", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "7-2-2", // 功能ID
                name: "编辑", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "7-2-3", // 功能ID
                name: "新增", // 功能名称
            }, {
                auth: "2", // 具体功能ID
                id: "7-2-4", // 功能ID
                name: "删除", // 功能名称
            }, ],
        }, ],
    }, ]
}

/**
 * 初始化权限数据
 * @param {*} menus 
 */
export function initAuthData(data, this_) {
    data.forEach(element => {
        element.children.forEach(model => {
            model.children.forEach(role => {
                this_.$set(role, "isDisable", false)
                if (role.auth == 1 || role.auth == "1") {
                    role.auth = true
                } else {
                    role.auth = false
                }
            });
        });
    });
    return data
}

/**
 * 判断当前的二级目录
 */
export function getSecondaryMenu(menus) {
    let path1 = ""
    let name1 = ""
    menus.forEach(menu => {
        if (menu.auth == true) {
            path1 = menu
            name1 = name
            return
        }
    });
    let secondaryMenu = sessionStorage.getItem("secondaryMenu")
    if (!secondaryMenu) {
        return { path: path1, name: name1 }
    }
    let path = sessionStorage.getItem("secondaryMenu") ? JSON.parse(sessionStorage.getItem("secondaryMenu")).path : ""
    let name = sessionStorage.getItem("secondaryMenu") ? JSON.parse(sessionStorage.getItem("secondaryMenu")).name : ""
    let flag = 0
    menus.forEach(menu => {
        if (menu.name == name && menu.auth == true) {
            flag = 1
            return
        }
    });
    if (flag == 1) {
        return { path: path, name: name }
    } else {
        return { path: path1, name: name1 }
    }
}

/**
 * 获取单个界面的权限
 */
export function getUserAuthority(userId, this_, deal) {
    let requestData = {
        userId: userId,
        search: {}
    }
    this_.$request("post", "/authority/getUserAuthority", requestData, (data) => {
        deal(data.authority)
    })
}

/**
 * 判断二级目录权限
 * @param {*} allData  总权限
 * @param {*} menuList  当前目录
 * @param {*} model  当前模块
 */
export function judgeDirectoryPermissions(allData, menuList, model) {

    let authList = []
    allData[0].model.forEach(element => {
        if (element.name == model) {
            authList = element.children
        }
    });

    menuList.forEach(menu => {
        menu.auth = "2"
        authList.forEach(auth => {
            if (auth.name == menu.name ||
                (auth.name == "业务管理" && (menu.name == "新设立申请" || menu.name == "迁入" || menu.name == "续签")) ||
                (auth.name == "文档管理" && (menu.name == "新设立" || menu.name == "迁址" || menu.name == "续签" ||
                    menu.name == "代理管理" || menu.name == "小程序" || menu.name == "个人中心" ||
                    menu.name == "企业管理")) ||
                (auth.name == "资讯管理" && (menu.name == "载体资讯"))) {
                auth.children.forEach(element => {
                    if (element.name == "查看" && element.auth == "1") {
                        // 查看功能对应显示与否
                        menu.auth = "1"
                    }
                });
            }
        });
    });

    return menuList

}

/**
 * 判断当前角色的菜单权限
 * @param {*} allData 
 * @param {*} menuList 
 */
export function determineCurrentMenu(allData, menuList) {
    allData.forEach(auth => {
        menuList.forEach(menu => {
            if (auth.name == menu.name) {
                // 权限菜单与目录菜单一致
                // 判断该菜单下所有二级菜单是否有显示的权限
                let flag = false
                auth.children.forEach(auth_menu => {
                    if (auth_menu.children[0].auth == "1") {
                        flag = true
                    }
                });

                if (flag) {
                    // 该菜单下有显示的子菜单
                    menu.auth = "1"
                } else {
                    menu.auth = '0'
                }
            }
        });
    });
    return menuList
}

/**
 *  判断当前模块的按钮权限
 * @param {*} allData  总权限
 * @param {*} butList 当前模块的所有按钮
 * @param {*} model1  一级模块
 * @param {*} model2 二级模块
 */
export function judgeButtonPermissions(allData, butList, model1, model2) {
    let authList = []
    allData.forEach(element => {
        if (element.name == model1) {
            element.children.forEach(ele => {
                if (ele.name == model2) {
                    authList = ele.children
                }
            });
        }
    });

    butList.forEach(but => {
        authList.forEach(auth => {
            if (but.key == auth.name) {
                if (auth.auth == "1") {
                    but.show = true
                } else {
                    but.show = false
                }
            }
        });
    });

    return butList
}

/**
 * 保存操作记录
 * 10.1 保存操作记录
 * @param {*} modelId  模块id，如订单，就是订单id
 * @param {*} operation  操作内容
 * @param {*} userId   操作人id
 * @param {*} model  模块
 * @param {*} this_  当前VUE
 * @param {*} deal  返回值
 */
export function saveOperationRecord(modelId, operation, userId, model, this_, deal) {
    let requestData = {
        modelId: modelId,
        operation: operation,
        userId: userId,
        model: model
    }

    this_.$request("post", "/operationRecord/saveOperationRecord", requestData, (data) => {
        deal(data)
    })
}

/**
 * 10.2 查看操作记录 按操作时间倒序
 * @param {*} modelId  模块id，如订单，就是订单id
 * @param {*} model  模块
 * @param {*} this_  当前VUE
 * @param {*} deal  返回值
 */
export function showOperationRecords(modelId, model, this_, deal) {
    let requestData = {
        modelId: modelId,
        model: model
    }

    this_.$request("post", "/operationRecord/showOperationRecords", requestData, (data) => {
        deal(data)
    })
}

/**
 *  获取安徽省/合肥市底下的区域
 * @param {*} flag  0 安徽  1 合肥
 * @param {*} array  当前区域下已有的区域  
 */
export function getAreaList(flag, array) {
    let options = []
    switch (flag) {
        case "0":
            // 安徽的除合肥外的剩下15个地级市
            options = [
                { label: "芜湖市", value: "芜湖市", disable: false },
                { label: "芜湖市火龙", value: "芜湖市火龙", disable: false },
                { label: "芜湖市南瑞", value: "芜湖市南瑞", disable: false },
                { label: "蚌埠市", value: "蚌埠市", disable: false },
                { label: "淮南市", value: "淮南市", disable: false },
                { label: "马鞍山市", value: "马鞍山市", disable: false },
                { label: "淮北市", value: "淮北市", disable: false },
                { label: "铜陵市", value: "铜陵市", disable: false },
                { label: "安庆市", value: "安庆市", disable: false },
                { label: "黄山市", value: "黄山市", disable: false },
                { label: "滁州市", value: "滁州市", disable: false },
                { label: "阜阳市", value: "阜阳市", disable: false },
                { label: "宿州市", value: "宿州市", disable: false },
                { label: "六安市", value: "六安市", disable: false },
                { label: "亳州市", value: "亳州市", disable: false },
                { label: "池州市", value: "池州市", disable: false },
                { label: "宣城市", value: "宣城市", disable: false },
            ]
            break
        case "1":
            // 合肥的九区三县
            options = [
                { label: "庐阳区", value: "庐阳区", disable: false },
                { label: "瑶海区", value: "瑶海区", disable: false },
                { label: "新站区", value: "新站区", disable: false },
                { label: "包河区", value: "包河区", disable: false },
                { label: "蜀山区", value: "蜀山区", disable: false },
                { label: "高新区", value: "高新区", disable: false },
                { label: "政务区", value: "政务区", disable: false },
                { label: "滨湖新区", value: "滨湖新区", disable: false },
                { label: "经开区", value: "经开区", disable: false },
                { label: "肥东县", value: "肥东县", disable: false },
                { label: "肥西县", value: "肥西县", disable: false },
                { label: "长丰县", value: "长丰县", disable: false },
                { label: "巢湖市", value: "巢湖市", disable: false },
                { label: "庐江县", value: "庐江县", disable: false },
            ]
            break
        default:
            break
    }

    options.forEach(element => {
        array.forEach(ele => {
            if (element.label == ele.name) {
                // 当前该区域已有  不可选 禁用
                element.disable = true
            }
        });
    });

    return options
}