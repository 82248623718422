<template>
  <!-- 过期列表 -->
  <div id="overdueList">
    <div class="overdueList-table">
      <el-table :data="overdueList" border style="width: 100%">
        <el-table-column prop="companyName" label="企业名称" width="220">
        </el-table-column>
        <el-table-column prop="lesseeName" label="承租人/所有人" width="130">
        </el-table-column>
        <el-table-column prop="clusterAddress" label="地址编号">
        </el-table-column>
        <el-table-column prop="expireDate" label="过期时间" width="110">
        </el-table-column>
        <el-table-column label="操作" width="110">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="copyInfo(scope.row)"
              >一键复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["overdueList"],
  data() {
    return {};
  },
  methods: {
    copyInfo(data) {
      let copyInput = document.createElement("textarea"); // 创建标签
      copyInput.value = `企业名称：${data.companyName}\n\n承租人/所有人：${data.lesseeName}\n\n地址编号：${data.clusterAddress}`; // 标签赋值
      document.body.appendChild(copyInput); // 添加标签
      copyInput.select(); // 选取文本框内容
      document.execCommand("copy"); // 调用浏览器复制
      document.body.removeChild(copyInput); // 复制成功后再将构造的标签 移除
      this.$message.success("复制成功!");
    },
  },
};
</script>

<style lang="less" scoped>
#overdueList {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .overdueList-table {
    width: 90%;
    height: 90%;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
