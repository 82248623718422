/**
 * 邮箱校验
 * @param {*} value 
 */
export function mailCheck(value) {
    const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
    let flag = true;
    if (value == "" || value == undefined || value == null) {
        flag = false;
    } else {
        if (!reg.test(value) && value != "") {
            flag = false;
        } else {
            flag = true;
        }
    }
    return flag;
}

export default mailCheck