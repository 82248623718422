<template>
  <div id="big-screen">
    <!-- 大屏展示数据 -->
    <div class="new-data table-borders">
      <!-- 今日新增企业数 -->
      <ScienceTechnologyAreDigital
        title="今日新增企业数"
        :data="todayNewCompanies"
      ></ScienceTechnologyAreDigital>
    </div>
    <div class="new-data table-borders">
      <!-- 本月新增企业数 -->
      <ScienceTechnologyAreDigital
        title="本月新增企业数"
        :data="thisMonthNewCompanies"
      ></ScienceTechnologyAreDigital>
    </div>
    <div class="polyline-data table-borders">
      <!-- 今日/本月 新增企业数据 -->
      <div class="title-top">
        <div class="tip"></div>
        <div class="title">今日新增企业数据</div>
        <div class="tip">
          <div
            :class="{ data: true, active: addFlag == '0' }"
            @click="changeData(0, '0')"
          >
            今日
          </div>
          |
          <div
            :class="{ data: true, active: addFlag == '1' }"
            @click="changeData(0, '1')"
          >
            本月
          </div>
        </div>
      </div>
      <LineData
        :xData="NewCompaniesList.xData"
        :data="NewCompaniesList.data"
        v-if="addActive"
      ></LineData>
    </div>
    <div class="map-data table-borders">
      <!-- 地图数据 -->
      <!-- <map-3-d></map-3-d> -->
      <map-3-d :data="mapData" :mapPath="mapPath"></map-3-d>
    </div>
    <div class="rank-data table-borders">
      <!-- 本月区域签约排名 -->
      <div class="title-top">
        <div class="tip"></div>
        <div class="title">本月区域签约排名</div>
        <div class="tip"></div>
      </div>
      <RankData :tableData="regionalSigningRank"></RankData>
    </div>
    <div class="bar-data table-borders">
      <!-- 本月/本年 企业区域纳税情况 -->
      <div class="title-top">
        <div class="tip"></div>
        <div class="title">企业区域纳税情况</div>
        <div class="tip">
          <div
            :class="{ data: true, active: areaFlag == '0' }"
            @click="changeData(1, '0')"
          >
            本月
          </div>
          |
          <div
            :class="{ data: true, active: areaFlag == '1' }"
            @click="changeData(1, '1')"
          >
            本年
          </div>
        </div>
      </div>
      <AHistogram
        :xData="enterpriseTaxSituationList.xData"
        :data="enterpriseTaxSituationList.data"
        v-if="areaActive"
      ></AHistogram>
    </div>
    <div class="pie-data table-borders">
      <!-- 本月/综合合肥市新增企业数占比 -->
      <div class="title-top">
        <div class="tip"></div>
        <div class="title">合肥市新增企业数占比</div>
        <div class="tip">
          <div
            :class="{ data: true, active: dutyFlag == '0' }"
            @click="changeData(2, '0')"
          >
            本月
          </div>
          |
          <div
            :class="{ data: true, active: dutyFlag == '1' }"
            @click="changeData(2, '1')"
          >
            总和
          </div>
        </div>
      </div>
      <PieData
        :pieData="newCompaniesAccountedForList"
        v-if="dutyActive"
      ></PieData>
    </div>
    <div class="pie-data table-borders">
      <!-- 行业分布TOP5 -->
      <div class="title-top">
        <div class="tip"></div>
        <div class="title">行业分布TOP5</div>
        <div class="tip"></div>
      </div>
      <Pie3D :pieData="industryDistributionList"></Pie3D>
    </div>
  </div>
</template>

<script>
import AHistogram from "../../components/a-histogram.vue"; // 柱状图
import LineData from "../../components/line-data.vue"; // 折线图
import Map3D from "../../components/map3d.vue"; // 3D地图
import Pie3D from "../../components/pie3d.vue"; // 3D饼状图
import PieData from "../../components/pie-data.vue"; // 饼状图
import ScienceTechnologyAreDigital from "../../components/science-technology-are-digital.vue"; // 数字大屏
import RankData from "../../components/rank-data.vue";
export default {
  components: {
    AHistogram,
    LineData,
    Map3D,
    Pie3D,
    PieData,
    ScienceTechnologyAreDigital,
    RankData,
  },
  data() {
    return {
      active: "0", // 0 :初始化界面  1 :局部切换数据
      flag: "1", // 0 安徽  1 合肥
      addFlag: "0", // 0 今日新增企业数据  1 本月新增企业数据
      addActive: true,
      areaFlag: "0", //0 本月企业区域纳税情况  1 本年企业区域纳税情况
      areaActive: true,
      dutyFlag: "0", // 0 本月新增企业数占比  1 总和新增企业占比
      dutyActive: true,

      todayNewCompanies: "", // 今日新增企业数   1,200  注: 每三位数进行,分割
      thisMonthNewCompanies: "", // 本月新增企业数  1,200 注:每三位数进行,分割
      industryDistributionList: [], // 行业分布TOP5
      regionalSigningRank: [], // 本月区域签约排名
      mapData: [], // 地图数据
      mapPath: require("@/assets/json/合肥市.json"),
      NewCompaniesList: {
        // 新增企业数据  addFlag 筛选数据
        xData: [],
        data: [],
      },
      enterpriseTaxSituationList: {
        // 企业区域纳税情况  areaFlag 筛选数据
        xData: [],
        data: [],
      },
      newCompaniesAccountedForList: [], // 新增企业数占比  dutyFlag 筛选数据
    };
  },
  methods: {
    /**
     * 15.1 获取大屏数据 不带筛选
     */
    getOtherData() {
      let requestData = {
        flag: this.flag,
      };
      this.$request("post", "/bigScreen/getOtherData", requestData, (data) => {
        console.log(data);
        this.todayNewCompanies = data.todayNewCompanies;
        this.thisMonthNewCompanies = data.thisMonthNewCompanies;
        this.industryDistributionList = data.industryDistributionList;
        this.regionalSigningRank = data.regionalSigningRank;
        this.mapData = data.mapData;
        this.newClientNum();
      });
    },
    /**
     * 15.2 获取 新增企业数据 大屏数据
     */
    newClientNum() {
      let requestData = {
        flag: this.flag,
        addFlag: this.addFlag,
      };
      this.$request("post", "/bigScreen/newClientNum", requestData, (data) => {
        this.NewCompaniesList.xData = data.xData;
        this.NewCompaniesList.data = data.data;
        if (this.active == "0") {
          this.taxesInfo();
        } else {
          this.addActive = true;
        }
      });
    },
    /**
     * 15.3 获取 企业区域纳税情况 大屏数据
     */
    taxesInfo() {
      let requestData = {
        flag: this.flag,
        areaFlag: this.areaFlag,
      };
      this.$request("post", "/bigScreen/taxesInfo", requestData, (data) => {
        this.enterpriseTaxSituationList.xData = data.xData;
        this.enterpriseTaxSituationList.data = data.data;

        if (this.active == "0") {
          this.newClientRat();
        } else {
          this.areaActive = true;
        }
      });
    },
    /**
     *  15.4 获取 新增企业数占比 大屏数据
     */
    newClientRat() {
      let requestData = {
        flag: this.flag,
        dutyFlag: this.dutyFlag,
      };
      this.$request("post", "/bigScreen/newClientRat", requestData, (data) => {
        this.newCompaniesAccountedForList = data;
        this.dutyActive = true;
      });
    },
    /**
     * 切换本年/本月数据
     */
    changeData(flag, active) {
      this.active = "1";
      switch (flag) {
        case 0:
          this.addFlag = active;
          this.addActive = false;
          this.newClientNum();
          break;
        case 1:
          this.areaFlag = active;
          this.areaActive = false;
          this.taxesInfo();
          break;
        case 2:
          this.dutyFlag = active;
          this.dutyActive = false;
          this.newClientRat();
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.active = "0";
    this.flag = this.$route.query.area == "合肥市" ? "1" : "0";
    this.mapPath =
      this.$route.query.area == "合肥市"
        ? require("@/assets/json/合肥市.json")
        : require("@/assets/json/安徽省.json");
    this.getOtherData();
  },
};
</script>

<style lang="less" scoped>
#big-screen {
  width: 100%;
  height: 100%;
  display: grid;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-template-columns: 2.95fr 2.95fr 5.9fr 5fr;
  grid-template-rows: 2.4fr 2.9fr 3fr;
  grid-template-areas:
    ". . map_ rank_"
    " polyline_ polyline_ map_ rank_"
    "bar_ bar_ . .";
  .new-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .map-data {
    grid-area: map_;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rank-data {
    grid-area: rank_;
  }
  .polyline-data {
    grid-area: polyline_;
  }
  .bar-data {
    grid-area: bar_;
  }
  .table-borders {
    border: solid 29px;
    border-image: url("../../assets/img/tip_bg.png") 29 29 29 29;
    box-sizing: border-box;
    position: relative;
    .title-top {
      position: absolute;
      width: 100%;
      height: 41px;
      display: flex;
      top: -24px;
      .tip {
        width: 0;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        font-size: 12px;
        color: #00d2fe;
        .data {
          padding: 0 5px;
          box-sizing: border-box;
          transition: 0.3s;
          z-index: 10;
          &:hover {
            cursor: pointer;
            color: @activeColor;
          }
        }
        .active {
          color: @activeColor;
        }
      }
      .title {
        width: 343px;
        height: 100%;
        background-image: url("../../assets/img/title_tip.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: rgb(0, 232, 254);
        color: #00d2fe;
        font-size: 16px;
        font-weight: bolder;
      }
    }
  }
}
</style>