<template>
  <div id="usens" class="div-flex-column-center" v-loading="loading">
    <!-- 科技感大屏数据 -->
    <div class="top-title div-flex-center">
      <div class="top-title-data"></div>
      <div class="data-title" @click="goto">
        <div class="title">安徽广电企服数据中心</div>
        <div class="title">anhui radio television enterprise data center</div>
      </div>
      <div class="top-title-data">
        <div class="date-value">{{ date1 }}</div>
        <div class="date-value">{{ nowWeek }}</div>
        <div class="date-value">{{ date2 }}</div>

        <div class="grid">|</div>
        <div class="date-value date-hove" @click="changeData">{{ area }}</div>
      </div>
    </div>
    <div class="content"><router-view v-if="isRouterAlive" /></div>
    <div class="bottom-tip div-flex-center" style="color: #00f0ff">
      {{ this.$copyright }}
    </div>
  </div>
</template>

<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      loading: true,
      date1: this.$formatTime(new Date(), "YYYY 年 MM 月 DD 日"), // 日期
      date2: this.$formatTime(new Date(), "HH:mm:ss"), // 时间
      nowWeek: "", // 周一~周日
      area: "合肥市",
      timer: null,
    };
  },
  mounted() {
    this.initTime();
    setTimeout(() => {
      this.$router.push({
        path: "/usens/big_screen",
        query: {
          area: this.area,
        },
      });
      this.loading = false;
    }, 500);
  },
  methods: {
    initTime() {
      let week = new Date().getDay();
      if (week == 1) {
        undefined;
        this.nowWeek = "周一";
      } else if (week == 2) {
        undefined;
        this.nowWeek = "周二";
      } else if (week == 3) {
        undefined;
        this.nowWeek = "周三";
      } else if (week == 4) {
        undefined;
        this.nowWeek = "周四";
      } else if (week == 5) {
        undefined;
        this.nowWeek = "周五";
      } else if (week == 6) {
        undefined;
        this.nowWeek = "周六";
      } else {
        undefined;
        this.nowWeek = "周日";
      }
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.date2 = this.$formatTime(new Date(), "HH:mm:ss");
        }, 1000);
      }
    },
    changeData() {
      this.area = this.area == "合肥市" ? "安徽省" : "合肥市";
      this.$router.push({
        path: "/usens/big_screen",
        query: {
          area: this.area,
        },
      });
      this.reload();
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    goto() {
      this.$router.push("/main");
    },
  },
};
</script>

<style lang="less" scoped>
#usens {
  width: 100%;
  height: 100%;
  // background-color: #10175e;
  background-image: url("../../assets/img/bg_usens.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .top-title {
    width: 100%;
    height: 100px;
    background-image: url("../../assets/img/top1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;

    .data-title {
      width: 100;
      height: 100%;
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      color: #ffffff;
      font-weight: bolder;
      .title {
        width: auto;
        height: auto;
        font-size: 33px;
        margin-top: 10px;
        letter-spacing: 8px;
        &:nth-last-child(1) {
          font-size: 16px;
          letter-spacing: 1px;
        }
      }
      cursor: pointer;
    }

    .top-title-data {
      width: auto;
      height: 100%;
      display: flex;
      align-items: flex-end;
      position: absolute;
      right: 0;
      bottom: 10px;
      .date-value {
        width: auto;
        height: auto;
        color: #ffffff;
        font-size: 18px;
        padding: 0 15px;
        box-sizing: border-box;
        &:nth-last-child(1) {
          margin-right: 60px;
        }
      }
      .date-hove {
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: @activeColor;
        }
      }
      .grid {
        // color: rgba(66, 15, 233, 0.513);
        color: #00f0ff;
        padding: 0 15px;
        font-size: 20px;
        width: auto;
        height: auto;
      }
    }
  }

  .content {
    width: 100%;
    height: 0;
    flex: 1;
    padding: 10px 20px;
    box-sizing: border-box;
  }

  .bottom-tip {
    width: 100%;
    height: 4%;
    font-size: @font-size-tip-copyright;
    // background-color: #fff;
  }
}
</style>