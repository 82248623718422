<template>
  <div
    id="client_infomation"
    class="div-flex-column-center"
    v-loading="loading"
  >
    <!-- 企业的税收详情 -->
    <div class="client-tip">
      <div class="name" @click="goto">客户管理</div>
      /
      <div class="name">{{ tempClient.clientName }}</div>
    </div>
    <div class="search-line">
      <el-input
        v-model="searchData.title"
        placeholder="标题"
        clearable
        @keyup.enter.native="initData"
      ></el-input>
      <el-date-picker
        v-model="searchData.time"
        type="date"
        value-format="yyyy-MM"
        placeholder="选择日期"
      >
      </el-date-picker>
    </div>
    <div class="content div-flex-center">
      <el-table :data="tableData" border style="width: 90%" height="70vh">
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button
              @click="handleClick(scope.row, it.active)"
              type="text"
              size="small"
              v-for="(it, ii) in tableOper"
              :key="ii"
              v-show="it.show && it.statusShow"
              >{{ it.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page">
      <el-pagination
        @current-change="initData"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>

        <div
          class="content div-flex-center"
          v-if="dialogData.type == 'choose_sign'"
        >
          <information_input :informationInputData="dialogData.data">
          </information_input>
        </div>
        <div class="content div-flex-center" v-if="dialogData.type == 'sign'">
          <SheetDetails :sheetDetailsData="dialogData.data"></SheetDetails>
        </div>
        <div class="buts div-flex-center">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
            @click="dialogHandleCilck(item.active)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SheetDetails from "../../components/sheet-detials.vue";
import * as CompanyData from "../../assets/js/companyData.js";
export default {
  components: { SheetDetails },
  data() {
    return {
      loading: false,
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        title: "", // 企业名称
        time: "",
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tableData: [],
      tableMenu: [
        {
          key: "title",
          title: "标题",
          width: "",
          fixed: false,
        },
        {
          key: "time",
          title: "时间",
          width: "",
          fixed: false,
        },
      ],

      tableOper: [
        {
          name: "查看",
          active: "show",
          show: true, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
        {
          name: "删除",
          active: "delete",
          show: true, // 由权限控制
          statusShow: true, // 由当前订单的状态控制
        },
      ],

      dialogData: {
        flag: false,
        title: "客户纳税信息",
        loading: true,
        type: "sign",
        baseData: {
          // 录入基本信息时,填写的表单信息
          title: "", // 自定义表单标题
          id: "", // 自定义表单ID
          time: "", // 时间
        },
        data: [],
        buttonList: [],
      },
      tempClient: {
        clientName: "",
        clientId: "",
      },
      tempData: {},
    };
  },
  methods: {
    /**
     * 初始化界面
     */
    initData() {
      let requestData = {
        clientId: this.tempClient.clientId,
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
        search: {
          time: this.searchData.time,
          title: this.searchData.title,
        },
      };
      this.$request("post", "/client/getFormInfoList", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.tableData;
      });
    },
    /**
     * 返回客户管理界面
     */
    goto() {
      this.$router.push({
        path: "/main/company/my_company",
        query: {
          name: "客户管理",
        },
      });
    },
    /**
     * 表格按钮操作事件
     */
    handleClick(item, active) {
      let requestData = {};
      this.tempData = item;
      switch (active) {
        case "show":
          // 查看纳税信息详情
          requestData = {
            id: item.id,
          };
          this.$request(
            "post",
            "/client/getFormInfoDetail",
            requestData,
            (data) => {
              this.dialogData.flag = true;
              this.dialogData.loading = true;
              setTimeout(() => {
                this.dialogData.title = "客户基本信息编辑";
                this.dialogData.data = data.templateData;
                this.dialogData.type = "sign";
                this.dialogData.buttonList = [
                  {
                    name: "确定",
                    active: "makesure_sign",
                  },
                  { name: "导出", active: "export" },
                ];
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "delete":
          //   删除纳税信息
          this.$confirm("此操作将永久删除该纳税记录, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: item.id,
              };
              this.$request(
                "post",
                "/client/delFormInfo",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.initData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;

        default:
          break;
      }
    },
    /**
     * dialog按钮操作事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "makesure_sign":
          // 确定修改当前纳税信息
          requestData = {
            id: this.tempData.id,
            templateData: this.dialogData.data,
          };
          // 非空判断
          for (
            let index = 0;
            index < requestData.templateData.length;
            index++
          ) {
            const element = requestData.templateData[index];
            if (element.not_null) {
              // 不可为空
              if (element.type == "6") {
                // 图片
                if (element.itemType == "1") {
                  // 身份证正反面
                  if (element.value2 == "" || element.value1 == "") {
                    this.$message({
                      message: "身份证正反面不可为空!",
                      type: "error",
                    });
                    return;
                  }
                } else if (element.value1 == "") {
                  // 普通图片 或 营业执照
                  this.$message({
                    message: element.title + "不可为空!",
                    type: "error",
                  });
                  return;
                }
              } else if (element.value == "" || element.value == []) {
                this.$message({
                  message: element.title + "不可为空!",
                  type: "error",
                });
                return;
              }
            }
          }
          this.$request(
            "post",
            "/client/updateTemplateData",
            requestData,
            (data) => {
              this.$message({
                message: "客户纳税信息修改成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.initData();
            }
          );
          break;
        case "export":
          // 导出
          window.location.href =
            this.$baseurl + "/client/exportFormData?id=" + this.tempData.id;
          break;
          // 关闭dialog
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.tempClient.clientId = this.$route.query.id;
    this.tempClient.clientName = this.$route.query.name;
    this.initData();
  },
};
</script>

<style lang="less" scoped>
#client_infomation {
  width: 100%;
  height: 100%;
  .client-tip {
    width: 95%;
    height: 30px;
    display: flex;
    align-items: center;
    font-size: 12px;

    > .name {
      width: auto;
      padding: 0 10px;
      box-sizing: border-box;
      transition: 0.3s;
      font-weight: bolder;
      &:hover {
        cursor: pointer;
        color: @baseColor;
        text-decoration: underline;
      }
    }
  }
  .search-line {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 25% !important;
      height: 30px !important;
      margin-left: 10px !important;
      .el-input__inner {
        height: 30px !important;
      }
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      margin: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: @baseColor;
      color: #ffffff;
      font-size: @font-size-tip-mini;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        background-color: @but-hoverColor;
      }
    }
  }
}
</style>