<template>
  <div id="test">
    <el-tree
      :data="data"
      :props="defaultProps"
      @node-click="handleNodeClick"
    ></el-tree>
  </div>
</template>

<script>
import SheetDetails from "../components/sheet-detials.vue";
import * as settingData from "../assets/js/setting.js";
export default {
  components: { SheetDetails },
  data() {
    return {
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1",
                },
              ],
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  methods: {
    handleNodeClick(data, node, item) {
      console.log(data, node, item);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#test {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  #test_echart {
    width: 50%;
    height: 900px;
    // background-color: @baseColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>