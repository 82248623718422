
<template>
  <div id="EchartsMapBar">
    <div id="mainBar" style="width: 100%; height: 100%"></div>
  </div>
</template>
 
<script>
export default {
  props: {
    xData: {
      type: Array,
      default: function () {
        return [
          "庐阳区",
          "蜀山区",
          "包河区",
          "瑶海区",
          "肥东县",
          "肥西县",
          "高新区",
        ];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [820, 932, 901, 934, 1290, 1330, 1320];
      },
    },
  },
  name: "EchartsMapBar",
  methods: {
    myEcharts() {
      let buildingsGeoJSON = require("../assets/json/合肥市.json");
      //   var regions = buildingsGeoJSON.features.map(function (feature) {
      //   return {
      //     name: feature.properties.name,
      //     value: Math.random(),
      //     height: feature.properties.height / 10
      //   };
      // });
      let myChart = this.$echarts.init(document.getElementById("mainBar"));
      this.$echarts.registerMap("buildings", buildingsGeoJSON);
      //配置图表
      var option = {
        //悬浮提示框
        grid: {
          left: "1%",
          right: "4%",
          bottom: "-1%",
          top: "15%",
          containLabel: true,
        },
        legend: {
          show: false,
        },
        dataZoom: {
          start: 0,
          end: 40,
          type: "inside",
          disabled: false,
        },
        xAxis: {
          type: "category",
          data: this.xData,
          axisLine: {
            lineStyle: {
              color: "#00e8fe",
              width: 1,
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          name: "单位:元",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#00e8fe",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "rgba(0, 232, 254,0.15)",
            },
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            data: this.data,
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#ffffff",
              },
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(69, 117, 180, 0.513)" },
                { offset: 0.3, color: "rgba(74, 180, 225, 0.513)" },
                { offset: 1, color: "rgba(49, 54, 150, 0.69)" },
              ]),
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    setTimeout(() => {
      this.myEcharts();
    }, 500);
  },
};
</script>
 
<style lang="less">
#EchartsMapBar {
  width: 100%;
  height: 100%;
}
</style>