export function getNoticeDialogData() {
    return [{
        title: "公告信息",
        tip: "",
        data: [{
            title: "标题:",
            tip: true, // 是否不得为空
            type: "input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入公告标题", // 提示文字
            key: ""
        }, {
            title: "类别:",
            tip: true, // 是否不得为空
            type: "radio_click", // 仅文字展示
            value: "普通公告",
            options: [{
                label: "普通公告",
                value: "普通公告"
            }, {
                label: "文档公告",
                value: "文档公告"
            }],
            landscape: true, // 是否横屏显示
            placeholder: "", // 提示文字
            key: ""
        }, {
            title: "具体内容:",
            tip: true, // 是否不得为空
            type: "textarea-input", // 仅文字展示
            value: "",
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入公告具体内容", // 提示文字
            key: "",
        }, {
            title: "文档链接:",
            tip: true, // 是否不得为空
            type: "select_obj_show", // 仅文字展示
            value: [],
            options: [],
            landscape: true, // 是否横屏显示
            placeholder: "请输入公告具体内容", // 提示文字
            key: "",
            show: false
        }]
    }]
}