<template>
  <div id="auth"></div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  created() {
    console.log(this.$route.query);
    console.log(location.href);
    let uu = location.href.split("#");
    let dd = uu[0].split("?");
    let d1 = dd[1].split("&");
    let d2 = d1[0].split("=");
    let d3 = d1[1].split("=");

    let status = d3[1].split("AAA");
    let contractId = status[0];
    let phone = status[1];
    let flag = status[2];
    let requestData = {
      code: d2[1],
      contractId: contractId,
    };
    this.$request("post", "/applet/getOpenId", requestData, (data) => {
      console.log(data, status, requestData);
      wx.miniProgram.redirectTo({
        url:
          "/pages/login/login_sign?openId=" +
          data.openId +
          "&contractId=" +
          contractId +
          "&phone=" +
          phone +
          "&flag=" +
          flag,
      });
    });
  },
  methods: {
    GetQueryString(name, str) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = str.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
      var context = "";
      if (r != null) context = r[2];
      reg = null;
      r = null;
      return context == null || context == "" || context == "undefined"
        ? ""
        : context;
    },
  },
};
</script>

<style>
</style>