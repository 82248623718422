<template>
  <div id="authority">
    <!-- 权限管理 -->
    <div class="role-list">
      <div class="role-span">用户角色:</div>
      <div class="role-content">
        <el-radio-group v-model="roleActive" @change="changeRole">
          <el-radio
            :label="item.id"
            v-for="(item, index) in roleList"
            :key="index"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="role-but" @click="submitAuth">
        <i class="el-icon-check"></i>提交
      </div>
      <div class="role-but" @click="cancelAuth">
        <i class="el-icon-close"></i>取消
      </div>
    </div>
    <div class="roles">
      <div class="roles-span"></div>
      <div class="roles-content" v-if="roleActive == '0'">暂无权限</div>
      <div class="roles-content" v-else>
        <el-collapse v-model="activeNames">
          <el-collapse-item
            :title="item.name"
            :name="item.name"
            v-for="(item, index) in modelData"
            :key="index"
          >
            <div
              class="roles-model"
              v-for="(model, i) in item.children"
              :key="i"
            >
              <div class="roles-model-title">{{ model.name }}</div>
              <div class="role-list">
                <div
                  class="role-item"
                  v-for="(role, ii) in model.children"
                  :key="ii"
                >
                  <el-checkbox
                    v-model="role.auth"
                    @change="changeAuth(role.id, role.auth)"
                    :disabled="role.isDisable"
                    >{{ role.name }}</el-checkbox
                  >
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      roleList: [], // 所有角色
      roleActive: "1",
      activeAuthList: [], // 当前有权限的功能ID
      activeNames: ["1"],
      modelData: this.$baseData.initAuthData(
        this.$baseData.getAllAuthorityTree(),
        this
      ), // 模块数据
    };
  },
  methods: {
    /**
     * 2.1.1 获取当前系统所有角色
     */
    getAllRoleList() {
      let requestData = {
        userId: this.userData.userId,
        search: {},
      };
      this.$request("post", "/role/getAllRoleList", requestData, (data) => {
        this.roleList = data;
        this.roleActive = this.roleList.length > 0 ? this.roleList[0].id : "0";
        this.changeRole(this.roleActive);
      });
    },
    /**
     * 切换角色
     * 2.1.2 根据角色ID,获取当前角色下所有权限
     */
    changeRole(roleId) {
      let requestData = {
        roleId: roleId,
        search: {},
      };
      this.$request(
        "post",
        "/authority/getAllAuthorityTreeByRoleId",
        requestData,
        (data) => {
          this.activeAuthList = data.activeAuthList;
          this.modelData = this.$baseData.initAuthData(data.model, this);
          if (roleId == "2" || roleId == "1" || roleId == "6") {
            //总公司 一级代理与区域代理 均不许新建业务
            this.modelData[0].children[1].children[0].isDisable = true;
            this.modelData[0].children[1].children[1].isDisable = true;
            this.modelData[0].children[1].children[2].isDisable = true;
            this.modelData[0].children[1].children[3].isDisable = true;
            this.modelData[0].children[1].children[4].isDisable = true;
          }
          if (roleId == "3" || roleId == "4" || roleId == "6") {
            // 区域代理员工 普通用户  总公司员工 均不许查看数据概况
            this.modelData[1].children[0].children[0].isDisable = true;
            this.modelData[1].children[0].children[1].isDisable = true;
          }
        }
      );
    },
    /**
     * 权限改变事件
     */
    changeAuth(id, value) {
      if (value) {
        this.activeAuthList.push(id);
      } else {
        this.activeAuthList.splice(this.activeAuthList.indexOf(id), 1);
      }
    },
    /**
     * 取消权限修改  重置权限界面
     */
    cancelAuth() {
      this.changeRole(this.roleActive);
    },
    /**
     * 权限提交
     */
    submitAuth() {
      let requestData = {
        authority: {
          role: this.roleActive,
          activeAuthList: this.activeAuthList,
        },
      };
      this.$request(
        "post",
        "/authority/updateAllAuthorityTree",
        requestData,
        (data) => {
          this.$message({
            message: "权限修改成功!",
            type: "success",
          });
          this.changeRole(this.roleActive);
        }
      );
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.getAllRoleList();
  },
};
</script>

<style lang="less" scoped>
#authority {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .role-list {
    width: 100%;
    height: 6vh;
    display: flex;
    align-items: center;
    .role-span {
      width: 8%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      letter-spacing: 3px;
    }
    .role-content {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .role-but {
      width: auto;
      height: auto;
      padding: 3px 10px;
      box-sizing: border-box;
      transition: 0.3s;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        color: @activeColor;
      }
      i {
        margin-right: 5px;
      }
    }
  }
  .roles {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    padding-right: 5%;
    padding-bottom: 2%;
    box-sizing: border-box;
    overflow: hidden;
    .roles-span {
      width: 8%;
      height: 100%;
    }
    .roles-content {
      width: 0;
      flex: 1;
      height: 100%;
      // background-color: aquamarine;
      border: 1px solid @borderColor;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgb(192, 192, 192);
      overflow: auto;
      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #f5f5f5ef;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 5px;
        background-color: #f5f5f500;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: rgba(5, 143, 255, 0.589);
      }

      .el-collapse {
        width: 98%;
        height: 98%;
        border-top: none;

        .roles-model {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;

          .roles-model-title {
            width: 100%;
            height: 4vh;
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
          }
          .role-list {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            padding-left: 20px;
            .role-item {
              width: auto;
              height: auto;
              padding: 5px 15px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .el-collapse-item__header {
    padding-left: 10px !important;
    box-sizing: border-box !important;
    font-size: 18px !important;
    font-weight: 600 !important;
  }
}
</style>