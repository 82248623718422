/**
 * 手机号校验
 * @param {*} value 
 */
function mobilePhoneNumberCheck(value) {
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    let flag = true;
    if (value == "" || value == undefined || value == null) {
        flag = false;
    } else {
        if (!reg.test(value) && value != "") {
            flag = false;
        } else {
            flag = true;
        }
    }
    console.log(flag);
    return flag;
}

export default mobilePhoneNumberCheck

// /**
//  * 邮箱校验
//  * @param {*} value 
//  */
// export function mailCheck(value) {
//     const reg = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
//     let flag = true;
//     if (value == "" || value == undefined || value == null) {
//         flag = false;
//     } else {
//         if (!reg.test(value) && value != "") {
//             flag = false;
//         } else {
//             flag = true;
//         }
//     }
//     return flag;
// }