export function getTableMenuData() {
    return [{
            key: "orderNo",
            title: "订单编号",
            width: "200",
            fixed: false,
        },
        {
            key: "companyName",
            title: "企业名称",
            width: "200",
            fixed: false,
        },
        {
            key: "clusterAddress",
            title: "地址编号",
            width: "200",
            fixed: false,
        },
        {
            key: "contractNo",
            title: "合同编号",
            width: "200",
            fixed: false,
        },
        {
            key: "lesseeName",
            title: "承租人/所有人",
            width: "200",
            fixed: false,
        },
        {
            key: "scopeBusiness",
            title: "经营范围",
            width: "200",
            fixed: false,
        },
        {
            key: "siteAddress",
            title: "实际经营地址",
            width: "200",
            fixed: false,
        },
        {
            key: "type",
            title: "类型",
            width: "100",
            fixed: 'right',
        },
        {
            key: "submitTime",
            title: "提交时间",
            width: "200",
            fixed: false,
        },
        {
            key: "submitUser",
            title: "提交人",
            width: "100",
            fixed: "right",
        },
        {
            key: "statusName",
            title: "状态",
            width: "100",
            fixed: "right",
        },
    ]
}

export function getNoNumTableMenuData() {
    return [{
            key: "companyName",
            title: "企业名称",
            width: "200",
            fixed: false,
        },
        {
            key: "clusterAddress",
            title: "地址编号",
            width: "200",
            fixed: false,
        },
        {
            key: "contractNo",
            title: "合同编号",
            width: "200",
            fixed: false,
        },
        {
            key: "lesseeName",
            title: "承租人/所有人",
            width: "200",
            fixed: false,
        },
        {
            key: "scopeBusiness",
            title: "经营范围",
            width: "200",
            fixed: false,
        },
        {
            key: "siteAddress",
            title: "实际经营地址",
            width: "200",
            fixed: false,
        },
        {
            key: "type",
            title: "类型",
            width: "100",
            fixed: false,
        },
        {
            key: "submitTime",
            title: "提交时间",
            width: "200",
            fixed: false,
        },
        {
            key: "submitUser",
            title: "提交人",
            width: "100",
            fixed: "right",
        },
        {
            key: "statusName",
            title: "状态",
            width: "100",
            fixed: "right",
        },
    ]
}