<template>
  <div id="pie-data">
    <div id="mainPie" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
export default {
  props: {
    pieData: {
      type: Array,
      default: function () {
        return [
          { value: 15, name: "庐阳区" },
          { value: 13, name: "蜀山区" },
          { value: 12, name: "包河区" },
          { value: 10, name: "肥西县" },
          { value: 16, name: "肥东县" },
          { value: 14, name: "长丰县" },
          { value: 10, name: "庐江县" },
          { value: 8, name: "瑶海区" },
          { value: 12, name: "巢湖市" },
        ];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    myEcharts() {
      let buildingsGeoJSON = require("../assets/json/合肥市.json");
      //   var regions = buildingsGeoJSON.features.map(function (feature) {
      //   return {
      //     name: feature.properties.name,
      //     value: Math.random(),
      //     height: feature.properties.height / 10
      //   };
      // });
      let myChart = this.$echarts.init(document.getElementById("mainPie"));
      this.$echarts.registerMap("buildings", buildingsGeoJSON);

      let option = {
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        color: [
          "#0074ff",
          "#f88f18",
          "#ebae18",
          "#cddc1f",
          "#3bdeac",
          "#00ceff",
          "#3cb8dc",
          "#2aa7db",
          "#3174e6",
        ],
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["45%", "70%"],
            center: ["50%", "55%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outer",
              color: "#fff",
              fontSize: 12,
              formatter: "{b}:{c}%",
              alignTo: "labelLine",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data: this.pieData,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    setTimeout(() => {
      this.myEcharts();
    }, 500);
  },
};
</script>

<style lang="less" scoped>
#pie-data {
  width: 100%;
  height: 100%;
}
</style>