<template>
  <div id="sheet-detials" class="div-flex-column-center">
    <!-- 客户信息录入的详情页 -->
    <div
      class="sheet-detials-line"
      v-for="(item, index) in sheetDetailsData"
      :key="index"
    >
      <div class="sheet-detials-line-title">
        <div class="tip" v-if="item.not_null">*</div>
        {{ item.title }}
      </div>
      <div class="sheet-detials-line-value">
        <div class="details" v-if="item.type == '0'">
          <!-- 单行文本 -->
          <el-input
            v-model="item.value"
            :placeholder="item.placeholder"
          ></el-input>
        </div>
        <div class="details" v-if="item.type == '1'">
          <!-- 多行文本 -->
          <el-input
            v-model="item.value"
            :placeholder="item.placeholder"
            type="textarea"
            :rows="3"
          ></el-input>
        </div>
        <div class="details" v-if="item.type == '2'">
          <!-- 日期选择 -->
          <el-date-picker
            v-model="item.value"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="details" v-if="item.type == '3'">
          <!-- 时间选择 -->
          <el-time-picker
            v-model="item.value"
            placeholder="任意时间点"
            value-format="HH:mm:ss"
          >
          </el-time-picker>
        </div>
        <div class="details" v-if="item.type == '4'">
          <!-- 单选 -->
          <el-radio-group v-model="item.value">
            <el-radio
              :label="it.value"
              v-for="(it, i) in item.options"
              :key="i"
              >{{ it.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div class="details" v-if="item.type == '5'">
          <!-- 多选 -->
          <el-checkbox-group v-model="item.value">
            <el-checkbox
              :label="it.value"
              v-for="(it, i) in item.options"
              :key="i"
              >{{ it.label }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="details" v-if="item.type == '6' && item.itemType == '0'">
          <!-- 普通图片上传 -->
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload1"
          >
            <img
              v-if="item.value1"
              :src="item.value1"
              class="avatar"
              style="width: 150px; height: 80px"
            />
            <div class="uploader-show" @click="itemUpload(item, index)" v-else>
              上传普通图片
              <i class="el-icon-upload"></i>
            </div>
          </el-upload>
        </div>
        <div class="details" v-if="item.type == '6' && item.itemType == '1'">
          <!-- 身份证上传 -->
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload1"
          >
            <img
              v-if="item.value1"
              :src="item.value1"
              class="avatar"
              style="width: 150px; height: 80px"
            />
            <div class="uploader-show" @click="itemUpload(item, index)" v-else>
              上传身份证正面照
              <i class="el-icon-upload"></i>
            </div>
          </el-upload>
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess1"
            :before-upload="beforeAvatarUpload1"
          >
            <img
              v-if="item.value2"
              :src="item.value2"
              class="avatar"
              style="width: 150px; height: 80px"
            />
            <div class="uploader-show" @click="itemUpload(item, index)" v-else>
              上传身份证反面照
              <i class="el-icon-upload"></i>
            </div>
          </el-upload>
        </div>
        <div class="details" v-if="item.type == '6' && item.itemType == '2'">
          <!-- 营业执照 -->
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="item.value1"
              :src="item.value1"
              class="avatar"
              style="width: 150px; height: 80px"
            />
            <div class="uploader-show" @click="itemUpload(item, index)" v-else>
              上传营业执照
              <i class="el-icon-upload"></i>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sheetDetailsData"],
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "客户税收信息附件",
      },
      tempUpload: {}, // 上传图片时记录当前上传对象
      tempItemIndex: 0, // 上传图片时记录上传的索引
    };
  },
  methods: {
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      // const isJPG = file.type === "application/pdf";
      // const isLt2M = file.size / 1024 / 1024 < 3;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     *图片上传前格式判断
     */
    beforeAvatarUpload1(file) {
      console.log("上传文件格式:", file.type);
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      this.sheetDetailsData[this.tempItemIndex].value1 = response.result.path;
      console.log(this.sheetDetailsData, this.tempItemIndex);
    },
    /**
     * 身份证反面照上传成功
     */
    handleAvatarSuccess1(response, file, fileList) {
      this.sheetDetailsData[this.tempItemIndex].value2 = response.result.path;
      console.log(this.sheetDetailsData, this.tempItemIndex);
    },
    /**
     * 上传事件同步点击事件
     */
    itemUpload(item, index) {
      console.log("上传事件同步点击事件:", item);
      this.tempUpload = JSON.parse(JSON.stringify(item));
      this.tempItemIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
#sheet-detials {
  width: 90%;
  height: 100%;
  overflow-y: auto;
  .sheet-detials-line {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 15px;
    .sheet-detials-line-title {
      width: 15%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      font-size: 14px;
      padding-right: 5px;
      box-sizing: border-box;
      .tip {
        width: auto;
        padding: 0 10px;
        box-sizing: border-box;
        margin-top: 5px;
        color: red;
      }
    }
    .sheet-detials-line-value {
      width: 0;
      flex: 1;
      height: auto;
      display: flex;
      .details {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  .uploader-show {
    width: 150px;
    height: 80px;
    border: 1px dotted @borderColor;
    color: @borderColor;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    font-size: 14px;
    margin: 0 10px;
    i {
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
      border-color: @activeColor;
      color: @activeColor;
    }
  }
}
</style>