<template>
  <div id="contract_records">
    <!-- 备案申请 -->
    <div class="fill-in-information div-flex-column-center" v-loading="loading">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="基本信息"> </el-tab-pane>
      </el-tabs>
      <div class="information-content div-flex-column-center" v-if="!showText">
        <information_input
          :informationInputData="orderList"
          @getVerification="getVerification"
          @handleAvatarSuccess="handleAvatarSuccess"
          @beforeAvatarUpload="beforeAvatarUpload"
        ></information_input>
        <div class="buts">
          <div class="but" @click="submit">提交备案</div>
        </div>
      </div>
      <div class="information-content div-flex-column-center" v-if="showText">
        <information_text :informationTextData="orderList"></information_text>
      </div>
    </div>
  </div>
</template>

<script>
import * as agentData from "../../assets/js/agentData.js";
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      activeName: "基本信息",
      showText: false, // 是否以文本形式展示信息
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      orderList: agentData.getOrderList(),
      id: "",
      loading: false,
    };
  },
  methods: {
    /**
     * 初始化界面
     * 3.2 查看备案的状态
     */
    initData() {
      let requestData = {
        userId: this.userData.userId,
      };
      this.$request("post", "/agent/getAgentStatus", requestData, (data) => {
        if (data.status == "审核中" || data.status == "已通过") {
          this.showText = true;
          this.id = data.agentId;
          this.getAgentInformation();
        } else if (data.status == "审核不通过") {
          this.showText = false;
          this.id = data.agentId;
          this.getAgentInformation();
        } else {
          this.showText = false;
        }
        this.loading = false;
      });
    },
    /**
     * 3.3 查看备案的具体信息
     */
    getAgentInformation() {
      let requestData = {
        agentId: this.id,
      };
      this.$request(
        "post",
        "/agent/getAgentInformation",
        requestData,
        (data) => {
          this.orderList = instructionsData.matchOrderData(
            this.orderList,
            data
          );
          let dd = {
            title: "备案状态:",
            tip: false, // 是否不得为空
            type: "text", // 仅文字展示
            value: data.status,
            landscape: false, // 是否横屏显示
          };
          this.orderList[0].data.push(dd);
        }
      );
    },
    /**
     * 发送验证码
     */
    getVerification(item) {
      if (item.value == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(item.value)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: item.value,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          item.isDisable = true;
          if (!item.timer) {
            item.timer = setInterval(() => {
              if (item.countdown > 0 && item.countdown <= 60) {
                item.countdown--;
                if (item.countdown !== 0) {
                  item.text = "重新发送(" + item.countdown + ")";
                } else {
                  clearInterval(item.timer);
                  item.text = "获取验证码";
                  item.countdown = 60;
                  item.timer = null;
                  item.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },
    /**
     * 文件上传前格式判断
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(data) {
      switch (data.response.result.title) {
        case "营业执照":
          this.orderList[1].data[3].value = data.response.result.path;
          this.orderList[1].data[3].isDisable = true;
          this.orderList[1].data[3].text = data.file.name;
          break;
        case "代理凭证":
          this.orderList[1].data[4].value = data.response.result.path;
          this.orderList[1].data[4].isDisable = true;
          this.orderList[1].data[4].text = data.file.name;
          break;
        case "身份证附件":
          this.orderList[2].data[6].value = data.response.result.path;
          this.orderList[2].data[6].isDisable = true;
          this.orderList[2].data[6].text = data.file.name;

          break;

        default:
          break;
      }
      console.log(this.orderList);
    },
    /**
     * 提交备案
     */
    submit() {
      let dt = instructionsData.checkToSubmitData(this.orderList, this);
      if (!dt.flag) {
        this.$message({
          message: dt.mes,
          type: "error",
        });
        return;
      }

      let requestData = instructionsData.formatSubmitData(this.orderList, this);
      this.$set(requestData, "userId", this.userData.userId);
      this.$request("post", "/agent/applyForAgent", requestData, (data) => {
        this.loading = true;
        this.initData();
      });
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "备案申请",
      auth: "2",
      path: "/main/agent/contract_records",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.initData();
  },
};
</script>

<style lang="less" scoped>
#contract_records {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: 100%;

    .el-tabs {
      width: 98%;
      height: auto;
    }

    .information-content {
      width: 98%;
      height: 0;
      flex: 1;
      overflow-y: auto;
      .model {
        width: 100%;
        height: auto;
        .title {
          width: 100%;
          height: 35px;
          position: relative;
          .line {
            width: 100%;
            height: 1px;
            background-color: rgba(160, 160, 160, 0.288);
            position: absolute;
            left: 0;
            top: 10px;
          }
          .text {
            width: auto;
            background-color: #ffffff;
            height: 100%;
            position: absolute;
            left: 30px;
            padding: 0 8px;
            font-size: 14px;
            span {
              color: red;
              margin-left: 8px;
            }
          }
        }
        .content {
          width: 100%;
          height: auto;
          display: flex;
          flex-wrap: wrap;
          .line {
            width: 49%;
            height: auto;
            min-height: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin: 8px 0;
            .line-title {
              width: 200px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              span {
                color: red;
                margin-top: 3px;
                margin-right: 3px;
                font-size: 18px;
              }
            }
            .line-value {
              width: 0;
              flex: 1;
              height: 100%;
              display: flex;
              align-items: flex-start;
              margin-left: 10px;
              .div1 {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }

              .textarea-but {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            .line-but {
              width: auto;
              min-width: 65px;
              height: 30px;
              padding: 0 15px;
              margin: 0 0 0 15px;
              background-color: @baseColor;
              color: #fff;
              font-size: 13px;
              border-radius: 8px;
              transition: 0.3s;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
                0 0 6px rgba(0, 0, 0, 0.04);
              &:hover {
                cursor: pointer;
                background-color: @but-hoverColor;
              }
            }
            .line-but-disable {
              background-color: @but-disableColor;
              &:hover {
                background-color: @but-disableColor;
                cursor: not-allowed;
              }
            }
          }
          .line-ver {
            // 横屏展示
            width: 98%;
          }
        }
      }
    }
  }
  .buts {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 200px;
    box-sizing: border-box;
    .occupi {
      width: 50px;
    }
  }
  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}
</style>