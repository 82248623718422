<template>
  <div id="register" class="div-flex-center">
    <div class="content div-flex-column-center">
      <div class="title div-flex-center">用户注册</div>
      <div class="lines div-flex-column-center">
        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            证件类型
          </div>
          <div class="line-value div-flex-center">
            <el-select v-model="cardType" placeholder="请选择证件类型">
              <el-option
                v-for="item in cardTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                clearable
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            身份证
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="idCard"
              placeholder="请输入身份证号"
              clearable
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">性别</div>
          <div class="line-value div-flex-center div-flex-start">
            <el-radio-group v-model="sex">
              <el-radio :label="'0'">男</el-radio>
              <el-radio :label="'1'">女</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            真实姓名
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="userName"
              placeholder="请输入真实姓名"
              clearable
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">电子邮箱</div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="email"
              placeholder="请输入电子邮箱"
              clearable
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">地址</div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="address"
              placeholder="请输入居住地址"
              clearable
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            手机号
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="phone"
              placeholder="请输入手机号"
              clearable
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            验证码
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="verification"
              placeholder="请输入验证码"
              clearable
            ></el-input>
          </div>
          <div
            :class="{
              'line-but': true,
              'div-flex-center': true,
              'line-but-disable': isDisable,
            }"
            @click="getVerification"
          >
            {{ butTip }}
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            密码
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="password"
              placeholder="请输入账号"
              clearable
              show-password
            ></el-input>
          </div>
        </div>

        <div class="line div-flex-center">
          <div class="line-span">
            <div class="tip">*</div>
            确认密码
          </div>
          <div class="line-value div-flex-center">
            <el-input
              v-model="password1"
              placeholder="请输入账号"
              clearable
              show-password
            ></el-input>
          </div>
        </div>
      </div>

      <div class="buts div-flex-center">
        <div class="but div-flex-center" @click="register">注册</div>
        <div class="but div-flex-center" @click="clear">重置</div>
      </div>

      <div class="line-tip" @click="goto">账号密码登录</div>
    </div>

    <div class="technical-support div-flex-center">
      {{ this.$copyright }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      idCard: "", // 身份证 非空
      cardType: "第二代居民身份证", // 证件类型 非空
      cardTypeList: [
        {
          label: "第二代居民身份证",
          value: "第二代居民身份证",
        },
      ],
      sex: "0", // 性别
      userName: "", // 用户姓名 非空
      email: "", // 邮箱
      address: "", // 地址
      phone: "", // 手机号 非空
      password: "", // 登录密码 非空
      password1: "", // 登录密码 非空
      verification: "", // 验证码

      isDisable: false,
      butTip: "获取验证码",
      timer: null,
      countdown: 60,
    };
  },
  methods: {
    /**
     * 获取验证码
     */
    getVerification() {
      if (this.phone == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: this.phone,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          this.isDisable = true;
          if (!this.timer) {
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= 60) {
                this.countdown--;
                if (this.countdown !== 0) {
                  this.butTip = "重新发送(" + this.countdown + ")";
                } else {
                  clearInterval(this.timer);
                  this.butTip = "获取验证码";
                  this.countdown = 60;
                  this.timer = null;
                  this.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },

    /**
     * 注册
     */
    register() {
      if (this.idCard == "") {
        this.$message({
          message: "身份证号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$idCardValidity(this.idCard)) {
        this.$message({
          message: "身份证格式不对!",
          type: "error",
        });
        return;
      }
      if (this.cardType == "") {
        this.$message({
          message: "证件类型不能为空!",
          type: "error",
        });
        return;
      }
      if (this.userName == "") {
        this.$message({
          message: "真实姓名不能为空!",
          type: "error",
        });
        return;
      }
      if (this.phone == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(this.phone)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      if (this.verification == "") {
        this.$message({
          message: "验证码不能为空!",
          type: "error",
        });
        return;
      }
      if (this.password == "" || this.password1 == "") {
        this.$message({
          message: "登录密码不能为空!",
          type: "error",
        });
        return;
      }
      if (this.password != this.password1) {
        this.$message({
          message: "两次登录密码不一致!",
          type: "error",
        });
        return;
      }

      let requestData = {
        idCard: this.idCard, // 身份证 非空
        cardType: this.cardType, // 证件类型 非空
        sex: this.sex, // 性别
        userName: this.userName, // 用户姓名 非空
        email: this.email, // 邮箱
        address: this.address, // 地址
        phone: this.phone, // 手机号 非空
        verification: this.verification, // 返回的验证码
        password: this.password, // 登录密码 非空
      };
      this.$request("post", "/user/register", requestData, (data) => {
        this.$message({
          message: "注册成功!",
          type: "success",
        });
        this.$router.push("/");
      });
    },

    /**
     * 重置
     */
    clear() {
      this.idCard = "";
      this.cardType = "1";
      this.sex = "0";
      this.userName = "";
      this.email = "";
      this.address = "";
      this.phone = "";
      this.password = "";
      this.password1 = "";
      this.verification = "";
    },

    /**
     * 回到登录页面
     */
    goto() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
#register {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/login_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .content {
    width: 38%;
    height: 90%;
    background-color: #ffffff3a;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.08);

    .title {
      width: 100%;
      height: 12%;
      border-bottom: 3px solid #fdfdfd6b;
      font-size: @font-size-main-title;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.308), 0 0 6px rgba(0, 0, 0, 0.192);
      letter-spacing: 20px;
      font-weight: bolder;
      color: #fff;
      margin-bottom: 4%;
    }

    .lines {
      width: 80%;
      height: 70%;
      overflow-y: auto;

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #f5f5f5ef;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 5px;
        background-color: #f5f5f500;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: rgba(5, 143, 255, 0.589);
      }
      .line {
        width: 100%;
        height: 65px;
        min-height: 65px;
        color: #fff;
        margin-top: 1.5%;
        .line-span {
          width: 13%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 10px;
          .tip {
            width: auto;
            height: 100%;
            color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 3px;
          }
        }
        .line-value {
          width: 0;
          height: 100%;
          flex: 1;
          margin-right: 10px;
          .el-select {
            width: 100%;
          }
          .el-radio {
            color: #fff;
          }
        }
        .line-but {
          width: auto;
          height: 60%;
          padding: 0 15px;
          margin-right: 10px;
          background-color: @baseColor;
          color: #fff;
          font-size: 15px;
          border-radius: 8px;
          transition: 0.3s;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
        }
        .line-but-disable {
          background-color: @but-disableColor;
          &:hover {
            background-color: @but-disableColor;
            cursor: not-allowed;
          }
        }
      }
    }

    .buts {
      width: 70%;
      height: 6%;
      margin-top: 3%;
      .but {
        width: 0;
        flex: 1;
        height: 100%;
        margin: 0 20px;
        border-radius: 10px;
        color: #fff;
        font-size: 15px;
        transition: 0.3s;
        box-sizing: border-box;
        &:nth-child(1) {
          background-color: @baseColor;
        }
        &:nth-child(2) {
          background-color: @but-cancelColor;
          border: 1px solid @baseColor;
        }
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
    }

    .line-tip {
      width: 100%;
      height: auto;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 13px;
      color: #0686fd;
      transition: 0.3s;
      padding-right: 20%;
      &:hover {
        cursor: pointer;
        color: #ff5202;
      }
    }
  }
}
</style>