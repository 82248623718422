<template>
  <div id="personal_information" v-loading="loading">
    <!-- 个人中心 -->
    <div
      class="line div-flex-center"
      v-for="(item, index) in data"
      :key="index"
    >
      <div class="title">
        <span v-if="item.tip">*</span>
        {{ item.title }}
      </div>
      <div class="value" v-if="item.type == 'text'">{{ item.value }}</div>
      <div class="value" v-else-if="item.type == 'radio'">
        <el-radio-group v-model="item.value">
          <el-radio
            :label="op.value"
            v-for="(op, o) in item.options"
            :key="o"
            >{{ op.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="value" v-else-if="item.type == 'input'">
        <el-input
          v-model="item.value"
          :placeholder="item.placeholder"
          clearable
        ></el-input>
      </div>
      <div class="value" v-else-if="item.type == 'input_password'">
        <el-input
          v-model="item.value"
          :placeholder="item.placeholder"
          clearable
          show-password
        ></el-input>
      </div>
      <div class="value" v-else-if="item.type == 'input-phone'">
        <el-input
          v-model="item.value"
          :placeholder="item.placeholder"
          clearable
        ></el-input>
        <div
          :class="{
            'line-but': true,
            'div-flex-center': true,
            'line-but-disable': item.isDisable,
          }"
          @click="getVerification(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </div>

    <div class="but div-flex-center" @click="submit">提交</div>
  </div>
</template>

<script>
import * as instructionsData from "../../assets/js/instructions.js";
export default {
  data() {
    return {
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      loading: true,
      data: [
        {
          title: "真实姓名",
          key: "name",
          tip: false, // 是否不得为空
          value: "",
          type: "text",
        },
        {
          title: "性别",
          key: "sex",
          tip: false, // 是否不得为空
          value: "0",
          type: "radio",
          options: [
            { label: "男", value: "0" },
            { label: "女", value: "1" },
          ],
        },
        {
          title: "证件类型",
          tip: false, // 是否不得为空
          key: "cardType",
          value: "第二代居民身份证",
          type: "text",
        },
        {
          title: "身份证号码",
          tip: true, // 是否不得为空
          key: "idCard",
          value: "",
          type: "input",
        },
        {
          title: "邮箱地址",
          tip: true, // 是否不得为空
          key: "email",
          value: "",
          type: "input",
          placeholder: "请输入邮箱地址", // 提示文字
        },
        {
          title: "地址",
          key: "address",
          tip: true, // 是否不得为空
          value: "",
          type: "input",
          placeholder: "请输入地址", // 提示文字
        },
        {
          title: "手机号码",
          tip: true, // 是否不得为空
          key: "phone",
          value: "",
          type: "input",
          placeholder: "请输入手机号码", // 提示文字
        },
        {
          title: "验证码",
          tip: true, // 是否不得为空
          key: "verificationCode",
          value: "",
          type: "input-phone",
          placeholder: "请输入您的手机验证码", // 提示文字
          text: "发送验证码",
          isDisable: false, // 发送验证码的按钮禁用状态
          timer: null, // 发送验证码计时器
          countdown: 60, // 倒计时时间
        },
        {
          title: "密码",
          tip: true, // 是否不得为空
          key: "password",
          value: "",
          type: "input_password",
          placeholder: "请输入8位以上数字和字母组合密码", // 提示文字
        },
        {
          title: "确认密码",
          tip: true, // 是否不得为空
          key: "password1",
          value: "",
          type: "input_password",
          placeholder: "请再次输入您的密码", // 提示文字
        },
      ],
    };
  },
  methods: {
    /**
     * 获取验证码
     */
    getVerification(item) {
      let phone = this.data[6].value;
      if (phone == "") {
        this.$message({
          message: "手机号不能为空!",
          type: "error",
        });
        return;
      }
      if (!this.$mobilePhoneNumberCheck(phone)) {
        this.$message({
          message: "手机号格式不对!",
          type: "error",
        });
        return;
      }
      let requestData = {
        phone: phone,
      };
      this.$request(
        "post",
        "/user/sendVerificationCode",
        requestData,
        (data) => {
          this.$message({
            message: "验证码发送成功!",
            type: "success",
          });
          item.isDisable = true;
          if (!item.timer) {
            item.timer = setInterval(() => {
              if (item.countdown > 0 && item.countdown <= 60) {
                item.countdown--;
                if (item.countdown !== 0) {
                  item.text = "重新发送(" + item.countdown + ")";
                } else {
                  clearInterval(item.timer);
                  item.text = "获取验证码";
                  item.countdown = 60;
                  item.timer = null;
                  item.isDisable = false;
                }
              }
            }, 1000);
          }
        }
      );
    },
    /**
     * 获取初始化数据
     */
    getInitData() {
      let requestData = {
        userId: this.userData.userId,
      };
      this.$request(
        "post",
        "/user/getUserInformationById",
        requestData,
        (data) => {
          this.data.forEach((element) => {
            element.value = data[element.key];
          });
        }
      );
    },
    /**
     * 修改个人信息
     */
    submit() {
      // 上传前数据校验
      for (let index = 0; index < this.data.length; index++) {
        const element = this.data[index];
        if (element.tip && element.value == "") {
          this.$message({
            message: "请将数据补充完整！",
            type: "error",
          });
          return;
        }
      }

      if (this.data[8].value != this.data[9].value) {
        this.$message({
          message: "两次密码不一致!",
          type: "error",
        });
        return;
      }

      let requestData = instructionsData.formatSubmitData(
        [{ data: this.data }],
        this
      );
      this.$set(requestData, "userId", this.userData.userId);
      this.$request(
        "post",
        "/user/updateUserInformation",
        requestData,
        (data) => {
          this.$message({
            message: "个人信息修改成功!",
            type: "success",
          });
          this.getInitData();
        }
      );
    },
  },
  mounted() {
    this.loading = false;
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.getInitData();
  },
};
</script>

<style lang="less" scoped>
#personal_information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  box-sizing: border-box;
  .line {
    width: 60%;
    height: 55px;
    padding: 10px;
    box-sizing: border-box;
    .title {
      width: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 15px;
      font-size: 15px;
      span {
        width: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: red;
      }
    }
    .value {
      width: 0;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .line-but {
        width: auto;
        min-width: 65px;
        height: 30px;
        padding: 0 15px;
        margin: 0 0 0 15px;
        background-color: @baseColor;
        color: #fff;
        font-size: 13px;
        border-radius: 8px;
        transition: 0.3s;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        &:hover {
          cursor: pointer;
          background-color: @but-hoverColor;
        }
      }
      .line-but-disable {
        background-color: @but-disableColor;
        &:hover {
          background-color: @but-disableColor;
          cursor: not-allowed;
        }
      }
    }
  }
  .but {
    width: 80px;
    min-width: 65px;
    height: 30px;
    padding: 0 15px;
    margin: 0 0 0 15px;
    background-color: @baseColor;
    color: #fff;
    font-size: 13px;
    border-radius: 8px;
    transition: 0.3s;
    margin-left: 200px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
}
</style>