<template>
  <div id="information-input" class="div-flex-column-center">
    <!-- 信息填写组件 -->
    <div
      class="model div-flex-column-center"
      v-for="(item, index) in informationInputData"
      :key="index"
    >
      <div class="title">
        <div class="line"></div>
        <div class="text">
          {{ item.title }}<span>{{ item.tip }}</span>
        </div>
      </div>
      <div class="content">
        <div
          :class="{ line: true, 'line-ver': it.landscape }"
          v-for="(it, ii) in item.data"
          :key="ii"
          v-show="
            !(
              (it.type == 'select_obj_show' || it.type == 'input_show') &&
              !it.show
            )
          "
        >
          <!-- <div class="line-title" v-if="it.type == 'text-tip'"></div>
          <div
            class="line-title"
            v-else-if="
              (it.type == 'select_obj_show' || it.type == 'input_show') &&
              !it.show
            "
          ></div> -->
          <div
            class="line-title"
            v-if="
              it.type != 'text-tip' &&
              !(
                (it.type == 'select_obj_show' || it.type == 'input_show') &&
                !it.show
              )
            "
          >
            <span v-if="it.tip">*</span>{{ it.title }}
          </div>
          <div class="line-value">
            <div v-if="it.type == 'pedometer'" class="div1">
              <!-- 计步器 -->
              <el-input-number
                v-model="it.value"
                :precision="1"
                :step="it.step"
                :max="it.max"
              ></el-input-number>
            </div>
            <div v-if="it.type == 'select-group'" class="div1">
              <!-- 分组下拉框 -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              >
                <el-option-group
                  v-for="group in it.options"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="(op, o) in group.options"
                    :key="o"
                    :label="op.label"
                    :value="op.value"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </div>
            <div v-if="it.type == 'input'" class="div1">
              <!-- 普通输入框 -->
              <el-input
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              ></el-input>
            </div>
            <div v-if="it.type == 'input_show' && it.show" class="div1">
              <!-- 普通输入框 是否显示 -->
              <el-input
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              ></el-input>
            </div>
            <div v-if="it.type == 'textarea-input'" class="div1">
              <!-- 文本输入框 -->
              <el-input
                type="textarea"
                :rows="3"
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              ></el-input>
            </div>
            <div v-if="it.type == 'input_password'" class="div1">
              <!-- 密码输入框 -->
              <el-input
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
                show-password
              ></el-input>
            </div>
            <div v-if="it.type == 'input-search'" class="div1">
              <!-- 搜索输入框 -->
              <el-autocomplete
                v-model="it.value"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
              ></el-autocomplete>
            </div>
            <div v-if="it.type == 'textarea-but'" class="div1">
              <!-- 按钮+文本域 -->
              <div class="textarea-but">
                <div class="but" @click="chooseScopeBusiness">
                  {{ it.placeholder }}
                </div>
                <el-input
                  type="textarea"
                  :rows="3"
                  :placeholder="it.placeholder"
                  v-model="it.value"
                  clearable
                  disabled
                >
                </el-input>
              </div>
            </div>
            <div v-if="it.type == 'radio'" class="div1">
              <!-- 单选框 -->
              <el-radio-group v-model="it.value">
                <el-radio
                  :label="op.value"
                  v-for="(op, o) in it.options"
                  :key="o"
                  >{{ op.label }}</el-radio
                >
              </el-radio-group>
            </div>
            <div v-if="it.type == 'radio_click'" class="div1">
              <!-- 单选框 带事件 -->
              <el-radio-group v-model="it.value" @change="radioChange">
                <el-radio
                  :label="op.value"
                  v-for="(op, o) in it.options"
                  :key="o"
                  >{{ op.label }}</el-radio
                >
              </el-radio-group>
            </div>
            <div v-if="it.type == 'input-disable'" class="div1">
              <!-- 输入框禁用 -->
              <el-input
                v-model="it.value"
                :placeholder="it.placeholder"
                disabled
              ></el-input>
            </div>
            <div v-if="it.type == 'input-phone'" class="div1">
              <!-- 手机号+验证码 -->
              <el-input
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              ></el-input>
              <div
                :class="{
                  'line-but': true,
                  'div-flex-center': true,
                  'line-but-disable': it.isDisable,
                }"
                @click="getVerification(it)"
              >
                {{ it.text }}
              </div>
            </div>
            <div class="div1" v-if="it.type == 'tip'" style="color: red">
              <!-- 文字提示 -->
              {{ it.value }}
            </div>
            <div class="div1" v-if="it.type == 'text-tip'">
              <!-- 普通文字展示 -->
              {{ it.value }}
            </div>
            <div class="div1" v-if="it.type == 'text'">
              <!-- 普通文字展示 -->
              {{ it.value }}
            </div>
            <div class="div1" v-if="it.type == 'time'">
              <el-date-picker
                v-model="it.value"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <div class="div1" v-if="it.type == 'select'">
              <!-- 普通下拉框 -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              >
                <el-option
                  v-for="(op, o) in it.options"
                  :key="o"
                  :label="op.label"
                  :value="op.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div1" v-if="it.type == 'select_obj'">
              <!-- 普通下拉框-对象 -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
                value-key="value"
              >
                <el-option
                  v-for="(op, o) in it.options"
                  :key="o"
                  :label="op.label"
                  :value="op"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div1" v-if="it.type == 'select_obj_show' && it.show">
              <!-- 普通下拉框-对象-多选 是否显示  -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
                multiple
                collapse-tags
                value-key="value"
              >
                <el-option
                  v-for="(op, o) in it.options"
                  :key="o"
                  :label="op.label"
                  :value="op"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div1" v-if="it.type == 'date_time'">
              <!-- 日期选择器 -->
              <el-date-picker
                v-model="it.value"
                type="date"
                placeholder="选择日期"
                :value-format="it.format"
              >
              </el-date-picker>
            </div>
            <div class="div1" v-if="it.type == 'select_disable'">
              <!-- 禁用下拉框-禁用选项 -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
              >
                <el-option
                  v-for="(op, o) in it.options"
                  :key="o"
                  :label="op.label"
                  :value="op.value"
                  :disabled="op.disable"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div1" v-if="it.type == 'select_disable_value'">
              <!-- 禁用下拉框-禁用值 -->
              <el-select
                v-model="it.value"
                :placeholder="it.placeholder"
                clearable
                disabled
              >
                <el-option
                  v-for="(op, o) in it.options"
                  :key="o"
                  :label="op.label"
                  :value="op.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div1" v-if="it.type == 'file'">
              <!-- 文件上传 -->
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :data="it.data"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :disabled="it.isDisable"
              >
                <div class="uploader-show">
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="it.isDisable"
                    >{{ it.placeholder }}</el-button
                  >
                  <div class="text-show">
                    {{ it.text }} <i class="el-icon-circle-close"></i>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    可上传PDF,PNG,JPG,JPEG,大小不超过3M
                  </div>
                </div>
              </el-upload>
            </div>
            <div class="div1" v-if="it.type == 'file_reuse'">
              <!-- 文件上传 可重新上传 -->
              <el-upload
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :data="it.data"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :disabled="it.isDisable"
              >
                <div class="uploader-show">
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="it.isDisable"
                    >{{ it.placeholder }}<i class="el-icon-upload"></i
                  ></el-button>
                  <div class="text-show">
                    {{ it.text }}
                    <i
                      v-if="it.text != ''"
                      class="el-icon-circle-close"
                      @click.stop="closeFile(it)"
                    ></i>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    可上传PDF,PNG,JPG,JPEG,大小不超过3M
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["informationInputData"],
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
    };
  },
  methods: {
    chooseScopeBusiness() {
      this.$emit("chooseScopeBusiness");
    },
    getVerification(item) {
      this.$emit("getVerification", item);
    },
    handleAvatarSuccess(response, file, fileList) {
      this.$emit("handleAvatarSuccess", { response, file, fileList });
    },
    beforeAvatarUpload(file) {
      this.$emit("beforeAvatarUpload", file);
    },
    radioChange(value) {
      this.$emit("radioChange", value);
    },
    // 行业分类搜索
    querySearchAsync(queryString, cb) {
      let requestData = {
        name: queryString,
      };
      this.$request(
        "post",
        "/dictionary/getAllIndustry",
        requestData,
        (data) => {
          console.log(data);
          let list = [];
          for (let index = 0; index < data.length; index++) {
            let dd = {
              name: data[index],
              value: data[index],
            };
            list.push(dd);
          }
          cb(list);
        }
      );
    },
    closeFile(item) {
      item.text = "";
      item.value = "";
      item.isDisable = false;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#information-input {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .model {
    width: 100%;
    height: auto;
    .title {
      width: 100%;
      height: 35px;
      position: relative;
      .line {
        width: 100%;
        height: 1px;
        background-color: rgba(160, 160, 160, 0.288);
        position: absolute;
        left: 0;
        top: 10px;
      }
      .text {
        width: auto;
        background-color: #ffffff;
        height: 100%;
        position: absolute;
        left: 30px;
        padding: 0 8px;
        font-size: 14px;
        span {
          color: red;
          margin-left: 8px;
        }
      }
    }
    .content {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      .line {
        width: 49%;
        height: auto;
        min-height: 50px;
        display: flex;
        align-items: center;
        font-size: 14px;
        margin: 8px 0;
        .line-title {
          width: 200px;
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          span {
            color: red;
            margin-top: 3px;
            margin-right: 3px;
            font-size: 18px;
          }
        }
        .line-value {
          width: 0;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: flex-start;
          margin-left: 10px;
          .div1 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }

          .textarea-but {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .line-but {
          width: auto;
          min-width: 65px;
          height: 30px;
          padding: 0 15px;
          margin: 0 0 0 15px;
          background-color: @baseColor;
          color: #fff;
          font-size: 13px;
          border-radius: 8px;
          transition: 0.3s;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          &:hover {
            cursor: pointer;
            background-color: @but-hoverColor;
          }
        }
        .line-but-disable {
          background-color: @but-disableColor;
          &:hover {
            background-color: @but-disableColor;
            cursor: not-allowed;
          }
        }
      }
      .line-ver {
        // 横屏展示
        width: 98%;
      }
    }
  }

  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
  .uploader-show {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text-show {
      margin: 0 8px;
    }
    .el-upload__tip {
      color: #9b9b9b8a;
    }
  }
}
</style>