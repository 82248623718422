// 引入sm
const sm = require('sm-crypto');

// sm2公钥
const sm2Key = 'MFkwEwYHKoZIzj0CAQYIKoEcz1UBgi0DQgAEOsLooorEuAhS8/tM/f0bPcN23uJn2YLYPDe9T/EGNKI/w7Afon+HmdvLwWGc+1uGAqiiSCMwSGxYJQ2A4Nownw=='

/**
 * 生成SMKey
 * @returns {string}
 */
export function sm4KeyGenerator() {
    const sm4KeySeed = '123456789abcdef'
    const keySize = 32
    let sm4key = ''
    while (sm4key.length < keySize) {
        const randomIndex = Math.floor(Math.random() * 100) % 16
        sm4key += sm4KeySeed.charAt(randomIndex)
    }
    return sm4key
}

/**
 * 生成SM2随机请求Key
 * @param sm4key
 * @param isStream
 * @returns {*}
 */
export function getRequestKey(sm4key, isStream) {
    let key = sm4key + '-' + Date.now()
    if (isStream) {
        key = '1-' + key
    } else {
        key = '0-' + key
    }
    // 1 - C1C3C2，0 - C1C2C3，默认为1
    const cipherMode = 0
        // 解密结果
        // sm.sm2.doDecrypt(sm.sm2.doEncrypt(key, publicKey, cipherMode), privateKey, cipherMode);
        // 加密结果
    return sm2Encrypt(key, sm2Key, cipherMode)
}

/**
 * 对请求参数加密
 *  */
export function encryptRequestData(data, sm4key, type) {
    let params
    let obj
    if (!data) {
        if (type === 'get') {
            params = 'tp=' + Date.now()
        } else {
            data = {
                tp: Date.now()
            }
            params = JSON.stringify(data)
        }
        obj = {
            cis_req_params: sm.sm4.encrypt(params, sm4key),
            cis_fingerprint: sm.sm3(params)
        }
    } else {
        if (type === 'get') {
            params = Object.keys(data).map(function(key) {
                return key + '=' + data[key]
            }).join('&')
        } else {
            params = JSON.stringify(data)
        }
        obj = {
            cis_req_params: sm.sm4.encrypt(params, sm4key),
            cis_fingerprint: sm.sm3(params)
        }
    }
    return obj
}

/**
 * 返回数据解密
 * @param data
 * @param sm4key
 * @returns {any}
 */
export function getResponseJson(data, sm4key) {
    const result = sm.sm4.decrypt(data, sm4key)
    return JSON.parse(result)
}