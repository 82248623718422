<template>
  <div id="index"></div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#index {
  width: 100%;
  height: 100%;
  // background-color: bisque;
  // background-image: url("../../assets/img/3.jpg");
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
}
</style>