
<template>
  <div id="EchartsMapPie">
    <div id="mainPie3D" style="width: 100%; height: 100%"></div>
  </div>
</template>
 
<script>
export default {
  props: {
    pieData: {
      type: Array,
      default: function () {
        return [
          {
            name: "专业设备",
            y: 26.36,
            sliced: true,
            selected: true,
          },
          {
            name: "集成电路",
            y: 25.45,
          },
          {
            name: "交通设备",
            y: 23.64,
          },
          {
            name: "工程机械",
            y: 14.55,
          },
          {
            name: "汽车及零部件",
            y: 10,
          },
        ];
      },
    },
  },
  name: "EchartsMapPie",
  methods: {
    initData() {
      var chart = this.$Highcharts.chart("mainPie3D", {
        chart: {
          type: "pie",
          backgroundColor: "rgba(0,0,0,0)",
          marginTop: 50,
          spacing: 0, // 内边距
          height: 220,
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        credits: {
          // 去除版权信息
          enabled: false,
        },
        title: {
          // show: false,
          text: "",
        },
        legend: {
          align: "right",
          itemStyle: {
            color: "#ffffff",
          },
          verticalAlign: "bottom ",
          y: 20,
        },
        tooltip: {
          //显示的悬浮框
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            depth: 35,
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
            showInLegend: true,
            //配置每一个图的颜色
            colors: ["#c300f4", "#1633f4", "#5bc0e2", "#7dc8e1", "#2430f6"],
          },
        },
        series: [
          {
            type: "pie",
            name: "",
            data: this.pieData,
          },
        ],
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.initData();
    }, 500);
  },
};
</script>
 
<style lang="less">
#EchartsMapPie {
  width: 100%;
  height: 100%;
}
</style>