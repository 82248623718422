<template>
  <div id="agent_management" class="div-flex-column-center" v-loading="loading">
    <!-- 代理管理 -->
    <div class="fill-in-information div-flex-column-center">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          v-if="item.show == true"
          :label="item.label"
          :name="item.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="search-line" v-if="activeName == '代理列表'">
      <el-input
        v-model="searchData.companyName"
        placeholder="企业名称"
        clearable
        @keyup.enter.native="getAllAgentList"
      ></el-input>
      <el-select
        v-model="searchData.type"
        clearable
        filterable
        placeholder="请选择代理类型"
        @change="getAllAgentList"
      >
        <el-option label="全部" value="0"> </el-option>
        <el-option label="区域代理" value="2"> </el-option>
        <el-option label="合作商" value="5"> </el-option>
      </el-select>
      <el-input
        v-model="searchData.legalPersonName"
        placeholder="法定代表人"
        clearable
        @keyup.enter.native="getAllAgentList"
      ></el-input>
      <el-input
        v-model="searchData.legalPersonPhone"
        placeholder="手机号"
        clearable
        @keyup.enter.native="getAllAgentList"
      ></el-input>
      <el-select
        v-model="searchData.status"
        clearable
        filterable
        placeholder="请选择备案状态"
        @change="getAllAgentList"
      >
        <el-option label="全部" value="-1"> </el-option>
        <el-option label="审核中" value="1"> </el-option>
        <el-option label="审核不通过" value="2"> </el-option>
        <el-option label="已通过" value="3"> </el-option>
      </el-select>
    </div>
    <div class="content div-flex-center">
      <el-table
        :data="tableData"
        border
        style="width: 90%"
        :row-style="changeRowTextColor"
        :height="activeName == '代理列表' ? '64vh' : '70vh'"
      >
        <el-table-column
          :prop="item.key"
          :label="item.title"
          :width="item.width"
          :fixed="item.key == 'orderNo' ? true : item.fixed"
          v-for="(item, index) in tableMenu"
          :key="index"
        >
          <template slot-scope="scope">
            <span v-if="item.key == 'orderNo'" class="tip">{{
              scope.row[item.key]
            }}</span>
            <span v-else>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot="header">
            <el-button
              v-if="activeName == '员工列表'"
              @click="handleClick({}, 'addEmpolyee')"
              type="text"
              size="small"
              icon="el-icon-circle-plus-outline"
              >添加</el-button
            >
          </template>
          <template slot-scope="scope">
            <el-button
              v-for="(item, index) in scope.row.tableOper"
              :key="index"
              v-show="item.show && item.statusShow"
              @click="handleClick(scope.row, item.active)"
              type="text"
              size="small"
              >{{ item.name }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @current-change="tabClick"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <div id="my-dialog" class="div-flex-center" v-if="dialogData.flag">
      <div
        class="my-dialog-content div-flex-column-center"
        v-loading="dialogData.loading"
      >
        <div class="top div-flex-center">
          <div class="title">{{ dialogData.title }}</div>
          <div class="perch-active"></div>
          <div class="close">
            <i class="el-icon-close" @click="dialogData.flag = false"></i>
          </div>
        </div>
        <div class="content div-flex-center">
          <information_text
            v-if="dialogData.type == 'information'"
            :informationTextData="dialogData.data"
          ></information_text>
          <information_input
            v-if="dialogData.type == 'input'"
            :informationInputData="dialogData.data"
            @radioChange="radioChange"
          >
          </information_input>

          <OperationData
            v-if="dialogData.type == 'operation'"
            :operationData="dialogData.data"
          ></OperationData>
        </div>
        <div class="buts">
          <div
            class="but"
            v-for="(item, index) in dialogData.buttonList"
            :key="index"
          >
            <el-button
              type="primary"
              size="small"
              v-if="item.active != 'upload'"
              plain
              @click="dialogHandleCilck(item.active)"
            >
              {{ item.name }}</el-button
            >
            <el-upload
              class="avatar-uploader"
              v-else
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <div class="uploader-show">
                <el-button type="primary" size="small" plain>{{
                  item.name
                }}</el-button>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as agentData from "../../assets/js/agentData.js";
import * as instructionsData from "../../assets/js/instructions.js";
import OperationData from "@/components/operation.vue";
export default {
  components: { OperationData },
  data() {
    return {
      loading: false,
      activeName: "代理列表",
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "备案凭证",
      },
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
      searchData: {
        companyName: "", // 企业名称
        legalPersonName: "", // 法定代表人姓名
        legalPersonPhone: "", // 法定代表人手机号
        status: "", // 备案状态 -1 全部  1-审核中  2-审核不通过 3-已通过
        type: "", // 代理类型
      },
      pageData: {
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页条数
        total: 0, // 总条目数
      },
      tabList: [
        {
          name: "代理列表",
          label: "代理列表",
          show: false,
          key: "审核",
        },
        {
          name: "代理申请列表",
          label: "代理申请列表",
          show: false,
          key: "凭证下发",
        },
        {
          name: "员工列表",
          label: "员工列表",
          show: false,
          key: "员工管理",
        },
      ],
      tableData: [],
      tableMenu: [],

      tableOper: [],

      dialogData: {
        flag: false,
        loading: false,
        title: "备案申请",
        data: [],
        type: "information",
        buttonList: [
          {
            name: "审核通过",
            active: "pass",
          },
          {
            name: "不通过",
            active: "fail",
          },
        ],
      },
      roleId: "",

      tempAgent: {},

      authData: [],
    };
  },
  methods: {
    /**
     * 初始化界面
     * 获取当前用户的权限列表,并适配到界面
     */
    initData() {
      this.$baseData.getUserAuthority(this.userData.userId, this, (data) => {
        this.roleId = data[0].roleId;
        this.tabList = this.$baseData.judgeButtonPermissions(
          data[0].model,
          this.tabList,
          "代理管理",
          "代理管理"
        );

        this.authData = data[0].model;
        console.log("当前权限", this.tabList);
        for (let index = 0; index < this.tabList.length; index++) {
          const element = this.tabList[index];
          if (element.show) {
            this.activeName = element.name;
            this.tabClick();
            return;
          }
        }
      });
    },
    /**
     * tab切换事件
     */
    tabClick() {
      console.log("this.activeName", this.activeName);
      this.tableOper = [];
      this.tableMenu = [];
      switch (this.activeName) {
        case "代理列表":
          this.tableOper = [
            {
              name: "查看详情",
              key: "查看详情",
              active: "show_detail",
              show: true, // 由权限控制
              statusShow: true, // 由当前订单的状态控制
            },
            {
              name: "充值",
              key: "查看详情",
              active: "pay",
              show: true, // 由权限控制
              statusShow: false, // 由当前订单的状态控制
            },
            {
              name: "添加额度",
              key: "查看详情",
              active: "lines",
              show: true, // 由权限控制
              statusShow: false, // 由当前订单的状态控制
            },
            {
              name: "支出详情",
              key: "查看详情",
              active: "pay_details",
              show: true, // 由权限控制
              statusShow: true, // 由当前订单的状态控制
            },
            {
              name: "账号停用",
              key: "代理停用",
              active: "account_disabl",
              show: true, // 由权限控制
              statusShow: false, // 由当前订单的状态控制
            },
          ];
          this.tableOper = this.$baseData.judgeButtonPermissions(
            this.authData,
            this.tableOper,
            "代理管理",
            "代理管理"
          );
          this.searchData.status = "-1";
          this.tableMenu = agentData.getAgentMenuData();
          this.getAllAgentList();
          break;
        case "代理申请列表":
          this.tableOper = [
            {
              name: "查看详情",
              key: "查看详情",
              active: "show_detail",
              show: true, // 由权限控制
              statusShow: true, // 由当前订单的状态控制
            },
            {
              name: "分配区域",
              key: "分配区域",
              active: "assign",
              show: true, // 由权限控制
              statusShow: false, // 由当前订单的状态控制
            },
          ];

          this.tableOper = this.$baseData.judgeButtonPermissions(
            this.authData,
            this.tableOper,
            "代理管理",
            "代理管理"
          );
          this.searchData.status = "1";
          this.tableMenu = agentData.getAgentMenuData();
          this.getAllAgentList();
          break;
        case "员工列表":
          this.tableOper = [];
          this.tableMenu = agentData.getEmpolyeeMenuData();
          this.getAllAgentByUserId();
          break;
        default:
          break;
      }
    },
    /**
     * 3.9 获取所有区域代理列表
     */
    getAllAgentList() {
      this.tableData = [];
      this.pageData.total = 0;
      let requestData = {
        userId: this.userData.userId,
        search: {
          companyName: this.searchData.companyName, // 企业名称
          legalPersonName: this.searchData.legalPersonName, // 法定代表人姓名
          legalPersonPhone: this.searchData.legalPersonPhone, // 法定代表人手机号
          status: this.searchData.status, // 备案状态 -1 全部  1-审核中  2-审核不通过 3-已通过
          type: this.searchData.type,
        },
        currentPage: this.pageData.currentPage,
        pageSize: this.pageData.pageSize,
      };
      this.$request("post", "/agent/getAllAgentList", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.tableData;
        this.tableData.forEach((element) => {
          let dd = JSON.parse(JSON.stringify(this.tableOper));
          if (
            this.activeName == "代理申请列表" &&
            element.status == "待分配区域"
          ) {
            dd[1].statusShow = true;
          }
          if (
            this.activeName == "代理列表" &&
            element.agentType == "区域代理" &&
            element.status == "已通过" &&
            element.stopFlag == "0"
          ) {
            // 只有二级代理并且状态为已通过才能添加额度
            dd[2].statusShow = true;
          }
          if (
            this.activeName == "代理列表" &&
            element.status == "已通过" &&
            element.stopFlag == "0"
          ) {
            // 只有已通过才能充值
            dd[1].statusShow = true;
          }
          if (
            this.activeName == "代理列表" &&
            element.stopFlag == "0" &&
            element.status == "已通过"
          ) {
            // 只有未停用的账号才能继续进行账号停用
            dd[4].statusShow = true;
            dd[4].active = "account_disabl";
            dd[4].name = "账号停用";
          }
          if (this.activeName == "代理列表" && element.stopFlag == "1") {
            // 以停用的账号才能继续进行账号停用
            dd[4].statusShow = true;
            dd[4].active = "account_enabl";
            dd[4].name = "账号启用";
          }
          this.$set(element, "tableOper", dd);
        });
        console.log("查看数据:", this.tableData);
      });
    },
    /**
     * 3.6 获取当前区域代理下所有的区域代理员工
     */
    getAllAgentByUserId() {
      let requestData = {
        userId: this.userData.userId, // 区域代理ID
        roleId: this.roleId, // 当前用户角色
        flag: "1", // 是否包含停用用户 0 不包含 1 包含
        search: {}, // 筛选条件
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/agent/getAllAgentByUserId",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.list;
          this.tableData.forEach((element) => {
            let dd = [
              {
                name: "编辑",
                key: "新增/修改区域代理员工",
                active: "editEmployee",
                show: true, // 由权限控制
                statusShow: true, // 由当前订单的状态控制
              },
              {
                name: "删除",
                key: "删除代理",
                active: "deleteEmployee",
                show: true, // 由权限控制
                statusShow: true, // 由当前订单的状态控制
              },
            ];
            this.$set(element, "tableOper", dd);
          });
        }
      );
    },
    /**
     * 分配区域时,选择安徽/合肥事件 返回挡圈范围的可选区域
     */
    radioChange(value) {
      console.log(
        "分配区域时,选择安徽/合肥事件 返回挡圈范围的可选区域:",
        value
      );
      let requestData = {
        search: {},
        flag: value,
      };
      this.dialogData.data[0].data[1].options = [];
      this.$request(
        "post",
        "/area/getAllNormalAreaList",
        requestData,
        (data) => {
          this.dialogData.data[0].data[1].options = this.$baseData.getAreaList(
            value,
            data.list
          );
        }
      );
    },
    /**
     * 表格按钮事件
     */
    handleClick(item, active) {
      this.tempAgent = item;
      let requestData = {};
      switch (active) {
        case "show_detail":
          // 查看详情  dialog显示详情,并展示是否通过
          requestData = {
            agentId: item.id,
          };
          this.$request(
            "post",
            "/agent/getAgentInformation",
            requestData,
            (data) => {
              this.dialogData.type = "information";
              this.dialogData.data = instructionsData.matchOrderData(
                agentData.getOrderList(),
                data
              );
              this.dialogData.data.splice(0, 1);
              this.dialogData.data[1].data.splice(3, 1);
              this.dialogData.buttonList = [];
              if (this.activeName == "代理列表") {
                this.dialogData.buttonList = [
                  {
                    name: "取消",
                    active: "cancel",
                  },
                ];
              } else if (this.activeName == "代理申请列表") {
                if (data.status == "审核中") {
                  this.dialogData.buttonList = [
                    {
                      name: "审核通过",
                      active: "pass",
                    },
                    {
                      name: "不通过",
                      active: "fail",
                    },
                  ];
                } else {
                  this.dialogData.buttonList = [
                    {
                      name: "取消",
                      active: "cancel",
                    },
                  ];
                }
              } else {
                this.dialogData.buttonList = [
                  {
                    name: "取消",
                    active: "cancel",
                  },
                ];
              }
              this.dialogData.flag = true;
            }
          );
          break;
        case "assign":
          // 区域分配
          this.dialogData.title = "区域分配";
          this.dialogData.type = "input";
          this.dialogData.data = agentData.getAssignDialogData();
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makesureAssign",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          requestData = {
            search: {},
            flag: "0",
          };
          this.$request(
            "post",
            "/area/getAllNormalAreaList",
            requestData,
            (data) => {
              this.dialogData.data[0].data[1].options = this.$baseData.getAreaList(
                "0",
                data.list
              );
              this.dialogData.flag = true;
            }
          );
          break;
        case "addEmpolyee":
          // 添加区域代理员工 或 总公司员工
          this.dialogData.type = "input";
          this.dialogData.data = agentData.getEmployeeDialogData();
          if (this.roleId == "1") {
            // 总公司
            this.dialogData.title = "添加总公司员工";
            this.dialogData.data[0].data[2].options = [
              {
                label: "总公司员工",
                value: "6",
              },
            ];
            this.dialogData.data[0].data[2].value = "6";
          } else if (this.roleId == "2") {
            // 区域代理
            this.dialogData.title = "添加区域代理员工";
            this.dialogData.data[0].data[2].options = [
              {
                label: "区域代理员工",
                value: "3",
              },
            ];
            this.dialogData.data[0].data[2].value = "3";
          }
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makesureAddEmployee",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "editEmployee":
          // 编辑区域代理员工
          this.dialogData.title = "编辑区域代理员工";
          this.dialogData.type = "input";
          this.dialogData.data = agentData.getEmployeeDialogData();
          this.dialogData.data[0].data[0].value = item.userName;
          this.dialogData.data[0].data[1].value = item.phone;
          this.dialogData.buttonList = [
            {
              name: "确认",
              active: "makesureEditEmployee",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "deleteEmployee":
          // 编辑区域代理员工
          this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                userId: item.id,
              };
              this.$request(
                "post",
                "/agent/delAgentInfo",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.tabClick();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "pay":
          // 充值
          requestData = {
            id: item.id,
          };
          this.dialogData.loading = true;
          this.dialogData.title = "代理余额调整";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "makesure_pay",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          this.$request("post", "/agent/getAgentMoney", requestData, (data) => {
            setTimeout(() => {
              this.dialogData.data = agentData.payDialogData();
              this.dialogData.data[0].data[0].value = data.money;
              console.log(this.dialogData);
              this.dialogData.loading = false;
            }, 500);
          });
          break;
        case "lines":
          // 添加额度
          this.dialogData.loading = true;
          this.dialogData.title = "代理额度调整";
          this.dialogData.type = "input";
          this.dialogData.buttonList = [
            {
              name: "确定",
              active: "makesure_lines",
            },
            {
              name: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          setTimeout(() => {
            this.dialogData.data = agentData.linesDialogData();
            this.dialogData.data[0].data[0].value = item.lines;
            console.log(this.dialogData);
            this.dialogData.loading = false;
          }, 500);
          break;
        case "pay_details":
          // 查看代理的支出详情
          this.$baseData.showOperationRecords(
            item.id,
            "agent",
            this,
            (saveData) => {
              this.dialogData.loading = true;
              this.dialogData.flag = true;
              setTimeout(() => {
                this.dialogData.data = saveData;
                this.dialogData.type = "operation";
                this.dialogData.title = "审批记录";
                this.dialogData.buttonList = [];
                this.dialogData.loading = false;
              }, 500);
            }
          );
          break;
        case "account_disabl":
          // 账号停用
          this.$confirm(
            "此操作将永久停用该账号,且该账号下所有员工账号也将被停止使用, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              requestData = {
                agentId: item.id,
              };
              this.$request(
                "post",
                "/agent/stopAgentUser",
                requestData,
                (data) => {
                  this.$message({
                    message: "账号停用成功!",
                    type: "success",
                  });
                  this.tabClick();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消!",
              });
            });

          break;
        case "account_enabl":
          //  账号启用
          this.$confirm(
            "此操作将重新启用该账号,同时,启用且该账号下所有员工账号, 是否继续?",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              requestData = {
                agentId: item.id,
              };
              this.$request(
                "post",
                "/agent/recoveryAgentUser",
                requestData,
                (data) => {
                  this.$message({
                    message: "账号启用成功!",
                    type: "success",
                  });
                  this.tabClick();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消!",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 弹出框按钮点击事件
     */
    dialogHandleCilck(active) {
      let requestData = {};
      switch (active) {
        case "pass":
          // 审核通过
          requestData = {
            id: this.tempAgent.id,
            userId: this.userData.userId,
          };
          this.$request("post", "/agent/agreeAgent", requestData, (data) => {
            this.$message({
              message: "审核通过!",
              type: "success",
            });
            this.dialogData.flag = false;
            this.tabClick();
          });
          break;
        case "fail":
          // 不通过审核
          this.$prompt("请审核意见", "审核意见", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
            .then(({ value }) => {
              requestData = {
                id: this.tempAgent.id,
                content: value,
                userId: this.userData.userId,
              };
              this.$request(
                "post",
                "/agent/failCheckAgentFile",
                requestData,
                (data) => {
                  this.dialogData.flag = false;
                  this.tabClick();
                }
              );
            })
            .catch(() => {});

          break;
        case "makesureAssign":
          // 确认创建并分配区域
          requestData = {
            name: this.dialogData.data[0].data[1].value,
            flag: this.dialogData.data[0].data[0].value,
            amount: this.dialogData.data[0].data[2].value,
            agentId: this.tempAgent.id,
            userId: this.userData.userId,
          };
          if (requestData.name == "") {
            this.$message({
              message: "区域名称不能为空!",
              type: "error",
            });
            return;
          }
          if (requestData.amount == "") {
            this.$message({
              message: "区域初始额度不能为空!",
              type: "error",
            });
            return;
          }
          this.$request("post", "/area/createArea", requestData, (data) => {
            this.$message({
              message: "区域分配成功!",
              type: "success",
            });
            this.dialogData.flag = false;
            this.tabClick();
          });
          break;
        case "makesureAddEmployee":
          // 确认添加区域代理员工
          requestData = {
            userId: this.userData.userId, // 当前区域代理ID
            userName: this.dialogData.data[0].data[0].value, // 总公司员工/区域代理员工姓名
            phone: this.dialogData.data[0].data[1].value, // 总公司员工/区域代理员工的手机号
            roleId: this.dialogData.data[0].data[2].value, // 角色ID
          };
          if (requestData.userName == "") {
            this.$message({
              message: "区域代理员工姓名不能为空!",
              type: "error",
            });
            return;
          }
          if (requestData.phone == "") {
            this.$message({
              message: "区域代理员工联系方式不能为空!",
              type: "error",
            });
            return;
          }
          this.$request(
            "post",
            "/agent/distributionAgent",
            requestData,
            (data) => {
              this.$message({
                message: "区域代理员工添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.tabClick();
            }
          );
          break;
        case "makesureEditEmployee":
          // 确认编辑区域代理员工
          requestData = {
            userId: this.tempAgent.id, // 当前区域代理ID
            userName: this.dialogData.data[0].data[0].value, // 区域代理员工姓名
            phone: this.dialogData.data[0].data[1].value, // 区域代理员工的手机号
          };
          if (requestData.userName == "") {
            this.$message({
              message: "区域代理员工姓名不能为空!",
              type: "error",
            });
            return;
          }
          if (requestData.phone == "") {
            this.$message({
              message: "区域代理员工联系方式不能为空!",
              type: "error",
            });
            return;
          }
          this.$request(
            "post",
            "/agent/updateAgentInfo",
            requestData,
            (data) => {
              this.$message({
                message: "区域代理员工添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.tabClick();
            }
          );
          break;
        case "makesure_pay":
          // 确定修改代理余额
          requestData = {
            id: this.tempAgent.id,
            money: this.dialogData.data[0].data[1].value,
          };
          this.$request(
            "post",
            "/agent/updateAgentMoney",
            requestData,
            (data) => {
              let mes = "";
              if (requestData.money.substr(0, 1) == "-") {
                let money = requestData.money.substr(
                  1,
                  requestData.money.length - 1
                );
                mes = "下调了" + money + "元";
              } else {
                mes = "充值" + requestData.money + "元";
              }
              this.$baseData.saveOperationRecord(
                this.tempAgent.id,
                mes,
                this.userData.userId,
                "agent",
                this,
                (saveData) => {
                  this.$message({
                    message: "余额调整成功!",
                    type: "success",
                  });
                  this.dialogData.flag = false;
                  this.tabClick();
                }
              );
            }
          );
          break;
        case "makesure_lines":
          // 确定调整额度
          requestData = {
            id: this.tempAgent.id,
            amount: this.dialogData.data[0].data[0].value,
          };
          this.$request(
            "post",
            "/area/updateAreaAmount",
            requestData,
            (data) => {
              this.$message({
                message: "额度调整成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.tabClick();
            }
          );

          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;

        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "备案凭证":
          requestData = {
            id: this.tempAgent.id,
            file: response.result.path,
            userId: this.userData.userId,
          };
          this.$request(
            "post",
            "/agent/uploadAgentFile",
            requestData,
            (data) => {
              this.$message({
                message: "凭证上传成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.tabClick();
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload(file) {
      console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    /**
     * 初始化表格数据
     */
    changeRowTextColor(data) {
      if (data.row.stopFlag == "1") {
        return {
          color: "red",
          height: "65px",
        };
      } else {
        return {
          height: "65px",
        };
      }
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "代理管理",
      auth: "2",
      path: "/main/agent/agent_management",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.initData();
  },
};
</script>

<style lang="less" scoped>
#agent_management {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: auto;
    .el-tabs {
      width: 98%;
      height: auto;
    }
  }
  .search-line {
    width: 100%;
    height: auto;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .el-input {
      width: 23% !important;
      height: 30px !important;
      margin-left: 10px !important;
      margin-top: 5px;
      .el-input__inner {
        height: 30px !important;
      }
    }
    .el-select,
    .el-range-editor {
      width: 23% !important;
      margin-left: 10px !important;
      margin-top: 5px;
    }
  }
  .content {
    width: 98%;
    height: 0;
    flex: 1;
    .tip {
      color: @baseColor;
      transition: 0.3s;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: @activeColor;
      }
    }
  }
  .page {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
  }
  .buts {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .but {
      width: auto;
      height: auto;
      padding: 5px 15px;
      box-sizing: border-box;
      border-radius: 5px;
      transition: 0.3s;
      margin-bottom: 8px;
    }
  }
}
</style>
