<template>
  <div id="area" class="div-flex-column-center" v-loading="loading">
    <!-- 合同基本设置 -->
    <div class="fill-in-information div-flex-column-center">
      <el-tabs v-model="activeName">
        <el-tab-pane label="扣费规则" name="扣费规则"> </el-tab-pane>
      </el-tabs>
      <div class="information-content div-flex-column-center">
        <information_input
          :informationInputData="orderList"
        ></information_input>
        <div class="buts">
          <div class="but" @click="save">保存</div>
          <div class="occupi"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as agentData from "../../assets/js/agentData.js";
export default {
  data() {
    return {
      activeName: "扣费规则",
      loading: false,
      orderList: agentData.getAreaDeductionRules(),
      userData: {
        userId: "",
        userName: "",
        icon: "",
      },
    };
  },
  methods: {
    /**
     * 4.9 查看跨区域金额和手续费百分比
     */
    getAreaMoney() {
      let requestData = {};
      this.$request("post", "/systemInfo/getPrice", requestData, (data) => {
        this.orderList[0].data[0].value = data.price;
      });
    },
    /**
     * 4.8 修改跨区域金额和手续费百分比
     */
    save() {
      let requestData = {
        price: this.orderList[0].data[0].value, // 合同单价(后端判断数字)
      };
      if (requestData.money == "") {
        this.$message({
          message: "合同单价不能为空！",
          type: "error",
        });
        return;
      }
      this.$request("post", "/systemInfo/updatePrice", requestData, (data) => {
        this.$message({
          message: "合同单价修改成功！",
          type: "success",
        });
      });
    },
  },
  mounted() {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    let dd = {
      id: "",
      name: "区域管理",
      auth: "2",
      path: "/main/setting/area",
    };
    sessionStorage.setItem("childernMenu", JSON.stringify(dd));
    this.getAreaMoney();
  },
};
</script>

<style lang="less" scoped>
#area {
  width: 100%;
  height: 100%;
  .fill-in-information {
    width: 100%;
    height: 100%;
    .el-tabs {
      width: 98%;
      height: auto;
    }

    .information-content {
      width: 98%;
      height: 0;
      flex: 1;
      overflow-y: auto;
    }
  }
  .buts {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 200px;
    box-sizing: border-box;
    .occupi {
      width: 50px;
    }
  }
  .but {
    width: auto;
    height: auto;
    padding: 5px 15px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: @baseColor;
    color: #ffffff;
    transition: 0.3s;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
      background-color: @but-hoverColor;
    }
  }
}
</style>