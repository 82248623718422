<template>
  <div id="rank-data">
    <div class="table-line title">
      <div class="index">TOP9</div>
      <div class="data">区域</div>
      <div class="data">签约数</div>
    </div>
    <div class="table-line" v-for="(item, index) in tableData" :key="index">
      <div :class="{ index: true, top: index < 3 }">{{ index + 1 }}</div>
      <div class="data">{{ item.area }}</div>
      <div class="data">{{ item.data }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [
          { area: "瑶海区", data: "1200" },
          { area: "肥西县", data: "1125" },
          { area: "巢湖市", data: "1056" },
          { area: "肥东县", data: "1024" },
          { area: "庐阳区", data: "995" },
          { area: "长丰县", data: "991" },
          { area: "庐江县", data: "982" },
          { area: "蜀山区", data: "970" },
          { area: "包河区", data: "967" },
        ];
      },
    },
  },
};
</script>

<style lang="less" scoped>
#rank-data {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 10px 10px;
  box-sizing: border-box;
  overflow-y: auto;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
    background-color: #f5f5f5ef;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
    border-radius: 5px;
    background-color: #f5f5f500;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: rgba(5, 143, 255, 0.589);
  }
  .table-line {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #0f419e98;
    transition: 0.3s;

    &:nth-child(2n) {
      background-color: #0f419e32;
    }
    &:nth-child(2) {
      color: #e03e5e;
    }
    &:nth-child(3) {
      color: #db732e;
    }
    &:nth-child(4) {
      color: #e6dc6b;
    }
    &:hover {
      cursor: pointer;
      background-color: #0f419e;
    }
    .index {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .top {
      background-image: url("../assets/img/rank_tip.png");
      background-repeat: no-repeat;
      background-position: center;
    }
    .data {
      width: 0;
      flex: 1.5;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background-color: #0a56e2b4;
    color: #03baee;
    font-weight: bolder;
  }
}
</style>