<template>
  <div id="sheet-list" class="div-flex-column-center">
    <!-- 自定义表单 排版 -->
    <div class="sheet-item" v-for="(item, index) in sheetListData" :key="index">
      <div class="tutle">
        <div
          :class="{ but: true, 'is-active': item.not_null }"
          @click="item.not_null = !item.not_null"
        >
          必填
        </div>
        <div class="but is-disable">{{ item.typeName }}</div>
        <div class="perch-active"></div>
        <div class="delete-but">
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            plain
            size="mini"
            @click="deleteItem(item, index)"
          ></el-button>
        </div>
      </div>
      <div class="sheet-data-name">
        <div class="sheet-data-name-label">
          <div class="tip">*</div>
          名称
        </div>
        <div class="sheet-data-name-value">
          <el-input v-model="item.title" placeholder="请输入名称"></el-input>
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '0' || item.type == '1'">
        <!-- 单行 / 多行文本 -->
        <div class="sheet-data-name">
          <div class="sheet-data-name-label">默认值</div>
          <div class="sheet-data-name-value">
            <el-input
              v-model="item.value"
              placeholder="请输入默认值"
            ></el-input>
          </div>
        </div>
        <div class="sheet-data-name">
          <div class="sheet-data-name-label">提示语</div>
          <div class="sheet-data-name-value">
            <el-input
              v-model="item.placeholder"
              placeholder="请输入提示语"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '2'">
        <!-- 日期选择 -->
        <div class="sheet-data-name">
          <div class="sheet-data-name-label">默认值</div>
          <div class="sheet-data-name-value">
            <el-date-picker
              v-model="item.value"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '3'">
        <!-- 时间选择 -->
        <div class="sheet-data-name">
          <div class="sheet-data-name-label">默认值</div>
          <div class="sheet-data-name-value">
            <el-time-picker
              v-model="item.value"
              placeholder="任意时间点"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </div>
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '4'">
        <!-- 单选 -->
        <div class="sheet-data-name" v-for="(it, i) in item.options" :key="i">
          <div class="sheet-data-name-label">单选选项</div>
          <div class="sheet-data-name-value">
            <el-input v-model="it.label" placeholder="选项名称"></el-input>
          </div>
          <div class="sheet-data-name-but">
            <el-button
              type="danger"
              icon="el-icon-minus"
              circle
              plain
              size="mini"
              @click="deleteSelect(item.options, it, i)"
            ></el-button>
          </div>
        </div>
        <div class="sheet-data-but">
          <el-button
            type="text"
            icon="el-icon-plus"
            @click="addSelect(item.options)"
            >新增选项</el-button
          >
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '5'">
        <!-- 多选 -->
        <div class="sheet-data-name" v-for="(it, i) in item.options" :key="i">
          <div class="sheet-data-name-label">多选选项</div>
          <div class="sheet-data-name-value">
            <el-input v-model="it.label" placeholder="选项名称"></el-input>
          </div>
          <div class="sheet-data-name-but">
            <el-button
              type="danger"
              icon="el-icon-minus"
              circle
              plain
              size="mini"
              @click="deleteSelect(item.options, it, i)"
            ></el-button>
          </div>
        </div>
        <div class="sheet-data-but">
          <el-button
            type="text"
            icon="el-icon-plus"
            @click="addSelect(item.options)"
            >新增选项</el-button
          >
        </div>
      </div>
      <div class="sheet-item-data" v-if="item.type == '6'">
        <!-- 图片 -->
        <div class="sheet-data-name">
          <div class="sheet-data-name-label">图片类型</div>
          <div class="sheet-data-name-value">
            <el-radio-group v-model="item.itemType">
              <el-radio :label="'0'">普通图片</el-radio>
              <el-radio :label="'1'">身份证</el-radio>
              <el-radio :label="'2'">营业执照</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sheetListData"],
  data() {
    return {};
  },
  methods: {
    /**
     * 移除当前item
     */
    deleteItem(item, index) {
      this.sheetListData.splice(index, 1);
    },
    /**
     * 移除当前多选/单选的选项值
     */
    deleteSelect(data, item, index) {
      data.splice(index, 1);
    },
    /**
     * 添加多选/单选的选项值
     */
    addSelect(data) {
      let dd = {
        label: "",
        value: "",
      };
      data.push(JSON.parse(JSON.stringify(dd)));
    },
  },
};
</script>

<style lang="less" scoped>
#sheet-list {
  width: 100%;
  height: 100%;

  .sheet-item {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    margin: 5px 0px;
    display: flex;
    flex-direction: column;
    .tutle {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      .but {
        width: auto;
        height: 35px;
        border: 1px solid @borderColor;
        border-radius: 3px;
        padding: 5px 20px;
        box-sizing: border-box;
        transition: 0.3s;
        color: @borderColor;
        margin: 0 15px;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
          border-color: @baseColor;
          color: @baseColor;
        }
      }
      .delete-but {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
      }
      .is-active {
        border-color: @baseColor;
        color: @baseColor;
      }
      .is-disable {
        background-color: #bebebe3a;
        color: rgba(68, 68, 68, 0.596);
        &:hover {
          cursor: not-allowed;
          color: rgba(68, 68, 68, 0.596);
          border-color: @borderColor;
        }
      }
    }
    .sheet-item-data {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      .sheet-data-but {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 12.8%;
        box-sizing: border-box;
      }
    }
  }
  .sheet-data-name {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    .sheet-data-name-label {
      width: 12.8%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      justify-content: flex-end;
      margin-right: 10px;
      .tip {
        width: auto;
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: red;
        margin-top: 5px;
        margin-right: 5px;
      }
    }
    .sheet-data-name-value {
      width: 0;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .sheet-data-name-but {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
    }
  }
}
</style>